import { useState, useEffect } from 'react';
import HomepageHeader from '../../components/headers/Header';
import SidebarUserPanel from '../../components/user/Sidebar';
import APICore from '../../services/APICore';
import moment from 'moment';
import Eprecriptiondownload from './downloadeprescription';




const Eprecription = () => {
    const [filterdata, setfilterdata] = useState("")
    const [forfilter, setdforfilter] = useState()
    const [carddata, setcarddata] = useState([])
    const [sortby, setsortby] = useState("")
    const [visible, setVisible] = useState(false)
    const [prescriptionid, setPrescriptionid] = useState("");
    const cardata = async () => {
        const result = await APICore.get(`/users/prescriptions?searchKey=${filterdata}&sort_by=${sortby}`)
        if (result != null) {
            if (result != undefined) {
                console.log(" Eprescription -------", result.data.city)
                setcarddata(result.data)
                setdforfilter(result.data)
                console.log(result.data, "Rakesh e prescription")
            }
        }
        console.log(result, "e prescription")
    }



    useEffect(() => { cardata(); }, [filterdata, sortby])



    return (
        <>
            <div className='outerhomepage'>
                {/*Top Navbar Menu*/}
                <HomepageHeader />
                <section className=" paddbottom">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <SidebarUserPanel />

                        <div className="right_panel ">
                            <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                <div class="content content-body">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="content-header">
                                                <h1 class="pull-left">
                                                    E-Prescriptions
                                                </h1>

                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <input type="text" className='form-control' value={filterdata} onChange={(e) => setfilterdata(e.target.value)} placeholder='Search' />
                                                </div>
                                                <div className='col-md-6'>
                                                    <select className='form-control' vlaue={sortby} onChange={(e) => setsortby(e.target.value)}>
                                                        <option value={""}>Sort by Date</option>
                                                        <option value={"sort_by_bookingid"}>Sort by Booking ID</option>
                                                        <option value={"sort_by_doctor"}>Sort by Doctor</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row spacetop">
                                        {console.log("E-Prescription for test", carddata)}
                                        {(carddata !== null && cardata !== undefined) ?

                                            (carddata != null && carddata != undefined) && carddata.map((key) => (
                                                <div className='col-lg-4 ' id="pdf-view">
                                                    <div class="db_box">
                                                        <div className='db_header'>
                                                            <h5>E-Prescription</h5>
                                                            <div className='dbh_right'>
                                                                <p>#{key.id}</p>
                                                                <span ><a onClick={() => setPrescriptionid(key.id)} ><i class="fa fa-download" aria-hidden="true"></i> </a></span>


                                                            </div>
                                                        </div>
                                                        <div className='db_body'>
                                                            <div class="infono">
                                                                <div className='info_text'>
                                                                    <h5>{key.name}</h5>
                                                                    <p>{(key.specializations != null) && key.specializations.map((item) => (item.type))} {key.city}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='db_footer'>
                                                            <div className='date'>Uploaded On  {(moment(key.created_at).utc()).format("MMM DD YYYY")}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )) : <p class="no_record">"No Record Found"</p>}

                                    </div>
                                    {prescriptionid !== undefined && prescriptionid !== null && prescriptionid !== "" ?
                                        <div id="capture">
                                            <Eprecriptiondownload prescriptionid={prescriptionid} setPrescriptionid={setPrescriptionid}></Eprecriptiondownload>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>

                        </div>



                    </div>
                </section>


            </div>




        </>
    );
}
export default Eprecription;