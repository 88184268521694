import React, { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import HomepageHeader from '../../components/headers/Header';
import SidebarUserPanel from '../../components/user/Sidebar';
import { BookingServices } from '../../services';


const Spincome = () => {
    const apiFunction = new BookingServices()
    const [overAllSummary, setOverAllSummary] = useState(null)
    const [transactions, setTransactions] = useState([])
    const [searchby, setSearchBy] = useState("")
    const [service, setService] = useState("")
    const [period, setPeriod] = useState("")
    const [isLoaded, setIsLoaded] = useState(true)

    useEffect(() => {
        LoadIncome()
    }, [searchby, service, period])

    const LoadIncome = () => {
        var fromdate=""
        var todate=""
        setIsLoaded(false)
        apiFunction.ServiceProviderIncomeAPI(searchby, service, period, fromdate, todate).then(result => {
            console.log(result,"result")
            if (result !== undefined) {
                if (result.success === true) {
                    console.log(result.data, "----prescription---")
                    setOverAllSummary(result.data.overAllSummary)
                    setTransactions(result.data.transactions)
                    setIsLoaded(true)

                }
            }
        })
    }
    return (
        <>
            <div className='outerhomepage'>
                {/*Top Navbar Menu*/}
                <HomepageHeader />
                <section className=" paddbottom">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <SidebarUserPanel />

                        <div className="right_panel ">
                            {
                                isLoaded ?
                                    <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                        <div class="content content-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="content-header">
                                                        <h1 class="pull-left">
                                                            My Income
                                                        </h1>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="row spacetop">
                                                <div className='col-lg-12'>
                                                    <div class="db_box">
                                                        <div className='db_header'>
                                                            <h5>Overall Summary</h5>
                                                            <div className='dbh_right'>
                                                                <div className='row'>
                                                                    <div className='col-md-4'>
                                                                        <input type="text" className='form-control input-sm' placeholder='Search' value={searchby} onChange={e => setSearchBy(e.target.value)} />
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <select className='form-control input-sm' value={service} onChange={e => setService(e.target.value)}>
                                                                            <option value={""}>All Service</option>
                                                                            <option value={"Doctor"}>Teleconsultation</option>
                                                                            <option value={"Nurse"}>Home Care</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <select className='form-control input-sm' value={period} onChange={(e)=>setPeriod(e.target.value)}>
                                                                            <option value={""}>Consolidated</option>
                                                                            <option value={"ThisWeek"}>This Week</option>
                                                                            <option value={"ThisMonth"}>This Month</option>
                                                                            <option value={"ThisYear"}>This Year</option>
                                                                            <option value={"PreviousWeek"}>Previous Week</option>
                                                                            <option value={"PreviousMonth"}>Previous Month</option>
                                                                            <option value={"PreviousYear"}>PreviousYear</option>
                                                                            <option value={"Custom"}>Custom</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(overAllSummary !== undefined && overAllSummary !== null) &&
                                                            <div className='db_body'>
                                                                <div class="spd_card">
                                                                    <div className='spd_card_list'>
                                                                        <p> Bookings</p>
                                                                        <h4>{overAllSummary.totalBookings}</h4>
                                                                    </div>
                                                                    <div className='spd_card_list'>
                                                                        <p> Completed</p>
                                                                        <h4>{overAllSummary.completedBookings}</h4>
                                                                    </div>
                                                                    <div className='spd_card_list'>
                                                                        <p> Income</p>
                                                                        <h4>{overAllSummary.income}</h4>
                                                                    </div>
                                                                    <div className='spd_card_list'>
                                                                        <p> Received</p>
                                                                        <h4>{overAllSummary.received}</h4>
                                                                    </div>
                                                                    <div className='spd_card_list'>
                                                                        <p> Balance</p>
                                                                        <h4>{overAllSummary.balance}</h4>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>

                                                <div className='col-lg-12'>
                                                    <div class="db_box">
                                                        <div className='db_header'>
                                                            <h5>Transactions</h5>
                                                        </div>
                                                        <div className='db_body'>
                                                            <div className='dr_table table-responsive'>
                                                                <table className='table table-bordered  '>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>ID</th>
                                                                            <th>Payment Date</th>
                                                                            <th>Payment Amount </th>
                                                                            <th>Payment Mode</th>
                                                                            <th>Transaction ID</th>
                                                                            <th>Remarks</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(transactions !== undefined && transactions !== null && transactions.length > 0) ?
                                                                            <tr>
                                                                                <td>1</td>
                                                                                <td>31 Oct 2022 </td>
                                                                                <td>25000</td>
                                                                                <td>Online (NEFT)</td>
                                                                                <td>T123456789 </td>
                                                                                <td>22 Bookings</td>
                                                                            </tr>
                                                                            : <tr><td colspan="6">No transactions found</td></tr>
                                                                        }

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div> :
                                    <div class="defaultloader">
                                        <ThreeDots
                                            height="80"
                                            width="80"
                                            radius="9"
                                            color="#056B66"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        /></div>
                            }
                        </div>
                    </div>
                </section>
            </div >




        </>
    );
}
export default Spincome;