import React, { useEffect } from 'react'
import { Footer, HomepageHeader } from '../../components'
import PatientNeed from "../../images/images/Patient-need.png"
import ImageOne from "../../images/images/dr1.png"
import ImageTwo from "../../images/images/dr2.png"
import ImageThree from "../../images/images/dr3.png"
import CheckTick from "../../images/images/check.png"
import { ServiceTypeObj } from '../../enums/Enums'
import {
    useNavigate
} from 'react-router-dom';
import { toast } from 'react-toastify'

const PartnerWithUs = () => {
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const redirectToLogin = (partnerWithUs) => {
     
        localStorage.setItem("wantTobeServiceProvider", partnerWithUs);
        toast.info("Please login or signup with your account")
        return navigate("/login");
    }
    return (
        <div class="outerhomepage">
            <HomepageHeader />

            {/*Inner Page Banner*/}
            <div className="topbanner_img">
                <img src={PatientNeed} alt="patient-need" />
            </div>
            {/*About*/}
            <div className="about_center paddtop">
                <h3 className="heading2">Partner with Fasttrack</h3>
                <p className="para2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec at gravida vitae quis. Tellus et varius tristique elit. Aliquet tristique viverra sed in consequat faucibus feugiat. Et, neque cursus...</p>
                {/* <div className="mt40"><a href="#" className="btn btn_fill">Learn more</a></div> */}
            </div>

            <section className="paddbottom">
                <div className="container">
                    <div className="flex_container">
                        <div className="left_wraper">
                            <div className="img_view"><img src={ImageOne} alt="ImageOne" /></div>
                        </div>
                        <div className="right_wraper">
                            <div className="content_wraper">
                                <div className="content_head">
                                    <h3>For Doctors</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam quam risus nunc, convallis ullamcorper. Arcu, dictum morbi diam tristique egestas urna, purus eget. Tempor, interdum ornare aliquam ultrices </p>
                                </div>
                                <div className="content_list">
                                    <p><img src={CheckTick} alt="checkImage" />Lorem ipsum dolor sit amet</p>
                                    <p><img src={CheckTick} alt="checkImage" />Lorem ipsum dolor sit amet</p>
                                    <p><img src={CheckTick} alt="checkImage" />Lorem ipsum dolor sit amet</p>
                                </div>
                                <div className="mt40"><button className="btn btn_fill" onClick={() => redirectToLogin(ServiceTypeObj.type1)}>Apply</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex_container">
                        <div className="left_wraper">
                            <div className="img_view"><img src={ImageTwo} alt="ImageTwo" /></div>
                        </div>
                        <div className="right_wraper">
                            <div className="content_wraper">
                                <div className="content_head">
                                    <h3>For Home care </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam quam risus nunc, convallis ullamcorper. Arcu, dictum morbi diam tristique egestas urna, purus eget. Tempor, interdum ornare aliquam ultrices </p>
                                </div>
                                <div className="content_list">
                                    <p><img src={CheckTick} alt="checkImage" />Lorem ipsum dolor sit amet</p>
                                    <p><img src={CheckTick} alt="checkImage" />Lorem ipsum dolor sit amet</p>
                                    <p><img src={CheckTick} alt="checkImage" />Lorem ipsum dolor sit amet</p>
                                </div>
                                <div className="mt40"><button className="btn btn_fill" onClick={() => redirectToLogin(ServiceTypeObj.type2)}>Apply</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex_container">
                        <div className="left_wraper">
                            <div className="img_view"><img src={ImageThree} alt="ImageThree" /></div>
                        </div>
                        <div className="right_wraper">
                            <div className="content_wraper">
                                <div className="content_head">
                                    <h3>For Ambulance</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam quam risus nunc, convallis ullamcorper. Arcu, dictum morbi diam tristique egestas urna, purus eget. Tempor, interdum ornare aliquam ultrices </p>
                                </div>
                                <div className="content_list">
                                    <p><img src={CheckTick} alt="checkImage" />Lorem ipsum dolor sit amet</p>
                                    <p><img src={CheckTick} alt="checkImage" />Lorem ipsum dolor sit amet</p>
                                    <p><img src={CheckTick} alt="checkImage" />Lorem ipsum dolor sit amet</p>
                                </div>
                                <div className="mt40">
                                    <button className="btn btn_fill" onClick={() => redirectToLogin(ServiceTypeObj.type3)}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}

export default PartnerWithUs