import React, { useEffect, useState } from 'react'
import HealthIssueListService from '../services/HealthIssueListService';
import VectorImage from "../images/images/Vector.png"

//  FAQs
const FaqsSections = () => {
    const apicalls = new HealthIssueListService();
    const [faqsList, setFaqsList] = useState([])

    useEffect(() => {
        loadFaqs();
    }, [])

    const loadFaqs = () => {
        apicalls.FaqsListFunctions().then(res => {
            if (res !== undefined) {
                var result = res
                if (result.success === true) {
                    setFaqsList(result.data)
                }
            }
        })
    }
    return (
        <section className="faq_sec  paddbottom" >
            <div className="container">
                <h4 className="faq_title">FAQs</h4>
                <div className="faq_flex">
                    {faqsList.length > 0 ?
                        faqsList.map(item =>
                        (
                            <div key={item.id + "faq"} className="faq_content">
                                <h4 className="faq_heading"><img src={VectorImage} alt />{item.question}</h4>
                                <p className="faq_text">{item.answer}</p>
                            </div>
                        ))
                        :
                        "No records found"
                    }
                </div>
            </div>
        </section >
    )
}

export default FaqsSections