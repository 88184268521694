import React, { useEffect, useState } from 'react'
import { HomepageHeader } from '../../components'
import VideoIcon from "../../images/images/vc_icon.png"
import userIcon from "../../images/adminpanel/UserIcon.svg"
import moment from 'moment'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { regHtml, regMobile } from '../../helpers/Validate'
import { toast } from 'react-toastify'
import { BookingServices } from '../../services'
import {
    err_10DigitMobile,
    err_NotFullName,
    err_NotMobile,
    HtmlErrorMessage
} from '../../enums/Enums'
import * as process from "process";
// import PayTmGatewayPage from './PayTmGatewayPage'

const BookingDetailsFillForm = () => {

    const apiFunction = new BookingServices();

    const { state } = useLocation();
    console.log(state, "setstate")

    const merchantId = process.env.REACT_APP_PayTM_MID

    const paytmOpenPaymentPageUrl = "https://securegw.paytm.in/theia/api/v1/showPaymentPage"

    const { slotidArr, slotDate, qualification, experience, specialization, slotTime, doctorName, doctorFee, profileId, profileType, service_provider_id, profile_pic } = state;

    const [isChecked, setIsChecked] = useState(false)
    const [profilepicture, setprofilepicture] = useState('')
    const [allergy, setAllergy] = useState(false)
    const [onMedication, setOnMeditation] = useState(false)
    const [pastMedicalIssue, setPastMedicalIssue] = useState(false)
    const [bookingType,setBookingType]=useState('New')
    const [bookAndPayForm, setbookAndPayForm] = useState({
        user_id: "",
        booking_date: "",
        service_provider_id: '',
        service_type: "",
        booked_for: "",
        total_amount: 0,
        other_name: "",
        other_mobile: "",
        short_description: "",
        paid_amount: 0,
        isSomeOneElse: false,
        appointment_date: '',
        slots: '',
        profile_pic: ""

    })
    console.log(bookAndPayForm, state, profile_pic, "state")
    const { user_id, booking_date,
        service_type, booked_for,
        total_amount, other_name,
        other_mobile, short_description,
        paid_amount, isSomeOneElse } = bookAndPayForm;

    const onInputChange = e => {
        setbookAndPayForm({
            ...bookAndPayForm, [e.target.name]:
                e.target.name === "other_mobile" ?
                    // Check if not number it will return nothing only for mobile
                    e.target.value.match(regMobile) ? e.target.value : ""
                    : e.target.value
        })
    }

    useEffect(() => {
        sessionStorage.setItem("notLoggedInBookingFlowUrlDetails", "");
        setbookAndPayForm({
            ...bookAndPayForm,
            user_id: profileId,
            booking_date: moment(new Date()).format("YYYY-MM-DD"),
            service_type: profileType,
            booked_for: isSomeOneElse ? "other" : "self",
            total_amount: doctorFee,
            other_name: other_name,
            other_mobile: other_mobile,
            short_description: "",
            paid_amount: 0,
            isSomeOneElse: isSomeOneElse,
            service_provider_id: service_provider_id,
            appointment_date: slotDate,
            profile_pic: profile_pic,


        });
    }, [])
    useEffect(() => { setprofilepicture(profile_pic) }, [])

    useEffect(() => {
        if (isSomeOneElse === true) {
            setbookAndPayForm({
                ...bookAndPayForm,
                other_name: "",
                other_mobile: "",
            })
        } else {
            setbookAndPayForm({
                ...bookAndPayForm,
                other_name: localStorage.getItem("user_name"),
                other_mobile: localStorage.getItem("user_mobile")
            })
        }
    }, [isSomeOneElse])

    function isDate(val) {
        // Cross realm comptatible
        return Object.prototype.toString.call(val) === '[object Date]'
    }

    function isObj(val) {
        return typeof val === 'object'
    }


    // For Paytm Stringify input field values
    function stringifyValue(val) {
        if (isObj(val) && !isDate(val)) {
            return JSON.stringify(val)
        } else {
            return val
        }
    }


    // Making a custom form by this code
    function buildForm({ action, params }) {
        const form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute('action', action)

        Object.keys(params).forEach(key => {
            const input = document.createElement('input')
            input.setAttribute('type', 'hidden')
            input.setAttribute('name', key)
            input.setAttribute('value', stringifyValue(params[key]))
            form.appendChild(input)
        })
        return form
    }

    function goToPaymentPage_Post_Information(details) {
        const form = buildForm(details)
        document.body.appendChild(form)
        form.submit()
        form.remove()
    }



    const bookAndPay = (e) => {
        e.preventDefault();
        if (!other_name?.trim()) {
            return toast.error(err_NotFullName)
        }
        else if (regHtml.test(other_name?.trim())) {
            return toast.error(HtmlErrorMessage)
        }
        else if (!other_mobile) {
            return toast.error(err_NotMobile)
        }
        else if (other_mobile.length !== 10) {
            return toast.error(err_10DigitMobile)
        } else {
            const data = {
                user_id: profileId,
                booking_date: moment(new Date()).format("YYYY-MM-DD"),
                service_type: profileType,
                booked_for: isSomeOneElse ? "other" : "self",
                total_amount: doctorFee,
                other_name: other_name,
                other_mobile: other_mobile,
                remark: short_description,
                paid_amount: 0,
                isSomeOneElse: isSomeOneElse,
                service_provider_id: service_provider_id,
                appointment_date: slotDate,
                slots: slotidArr,
                allergy: allergy,
                past_medical_issue: pastMedicalIssue,
                on_medication: onMedication,
                booking_type:bookingType

            }
            console.log(data)
            apiFunction.BookAndPay(data).then(result => {
                console.log(result, "result")
                if (result !== undefined) {
                    if (result.success === true) {
                        localStorage.setItem("BookingId", result.data.id)

                        const datanew = {
                            amount: doctorFee,
                            requestfrom: "WEB",
                            booking_id: result.data.id
                        }
                        console.log(result)
                        apiFunction.PaymentOrder(datanew).then(result => {
                            if (result !== undefined) {
                                if (result.success === true) {

                                    const transaction_token = result.data.transaction_token
                                    const order_id = result.data.order_id

                                    var information = {
                                        action: `${paytmOpenPaymentPageUrl}?mid=${merchantId}&orderId=${order_id}`,
                                        params: {
                                            mid: merchantId,
                                            orderId: order_id,
                                            txnToken: transaction_token,
                                            TXN_AMOUNT: doctorFee/100,
                                            CURRENCY: "INR",
                                            WEBSITE: "WEBSTAGING"
                                        }
                                    }

                                    localStorage.setItem("paymentAmount", doctorFee)
                                    // this is function that show's paytm gate way
                                    goToPaymentPage_Post_Information(information)
                                }
                            }
                        })
                    }

                }
            })

        }
    }
    // handle medical history
    const handleMedicalHistory = (medicalAttribute) => {
        let checkbox = document.getElementById(medicalAttribute)
        if (checkbox.checked != true) {
            console.log(checkbox.checked)
            checkbox.checked = true
        } else {
            console.log(checkbox.checked)
            checkbox.checked = false
        }
    }
    const handleCheckBox = () => {
        if (isChecked) {
            setIsChecked(false)
        } else {
            setIsChecked(true)
        }
    }
    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className="paddtop paddbottom">
                <div className="">
                    <div className="booking_flex">
                        <div className="left_itme">
                            <div className="bkvideo_consult bg_light">
                                <div className="vc_header">
                                    <h4><img src={VideoIcon} />&nbsp;&nbsp;Video Consultation</h4>
                                </div>

                                <div className="vc_datetime">
                                    <div className="date">
                                        <h4>On {moment(slotDate).format('MMMM DD,YYYY')}</h4>
                                        <Link
                                            to="/show-profile-and-slots"
                                            state={{ userid: profileId, searchType: profileType }}>
                                            Change date &amp; time
                                        </Link>
                                    </div>
                                    <div className="time">
                                        At {slotTime}
                                    </div>
                                </div>
                                <div className="bk_drblock">
                                    <div className="dr_photo">
                                        <div className="doctor_img">
                                            <div className="dr_photo">
                                                <img src={(profilepicture) ? (profilepicture) : userIcon} alt="service-provider" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="doctor_bio">
                                        <h3 className="dr_name">{doctorName}</h3>
                                        <p className="dr_quali">{qualification}</p>
                                        <p className="dr_exp">{specialization[0].type}</p>
                                        <p className="dr_exp">{experience} years</p>

                                    </div>
                                </div>
                            </div>
                            <Link
                                to="/show-profile-and-slots"
                                state={{ userid: profileId, searchType: profileType }}
                                className="goback mt20">
                                <i className="fa fa-arrow-left" />
                                &nbsp; &nbsp;Go Back
                            </Link>
                        </div>
                        <div className="right_itme">
                            <div className="booking_dtl">
                                <h3 className="bk_title">Patient details</h3>
                                <h4 className="bk_sub_title">This video consultation is for:</h4>
                                <div className="vc_for">
                                    <div className="vc_rb">
                                        {/* Radio Buttons */}
                                        <input type="radio"
                                            id="test1"
                                            name="radio-group"
                                            defaultChecked={isSomeOneElse === false ? true : false}
                                            onClick={() =>
                                                setbookAndPayForm({
                                                    ...bookAndPayForm,
                                                    booked_for: "self",
                                                    isSomeOneElse: false
                                                })}
                                        />
                                        <label htmlFor="test1">{localStorage.getItem("user_name")}</label>
                                    </div>
                                    <hr />
                                    <div className="vc_rb">
                                        {/* Radio Buttons */}
                                        <input type="radio"
                                            id="test2"
                                            name="radio-group"
                                            onClick={() =>
                                                setbookAndPayForm({
                                                    ...bookAndPayForm,
                                                    booked_for: "other",
                                                    isSomeOneElse: true
                                                })}
                                            defaultChecked={isSomeOneElse === true ? true : false}
                                        />
                                        <label htmlFor="test2">Someone else</label>
                                    </div>
                                </div>
                                <h4 className="bk_sub_title">Please provide following information </h4>
                                <form onSubmit={(e) => bookAndPay(e)}>
                                    <div className>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Full name *</label>
                                            <input
                                                onChange={(e) => onInputChange(e)}
                                                value={other_name} name="other_name"
                                                type="text" className="form-control"
                                                placeholder="Enter full name"
                                                readOnly={isSomeOneElse ? false : true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Mobile *</label>
                                            <input
                                                readOnly={isSomeOneElse ? false : true}
                                                type="text"
                                                maxLength={10}
                                                onChange={(e) => onInputChange(e)}
                                                value={other_mobile}
                                                name="other_mobile"
                                                className="form-control"
                                                placeholder="Enter mobile" />
                                        </div>
                                        <div className="form-group">
                                            <label>Medical History</label>
                                            <div className='gap-3'>
                                                <input type="checkbox" id='Allergies' onChange={e=>setAllergy(!allergy)} checked={allergy}/>
                                                <label className='mx-3-medical'>Allergies </label>
                                                <input type="checkbox" id='Past-Medical-Issue' onChange={e=>setPastMedicalIssue(!pastMedicalIssue)} checked={pastMedicalIssue}/>
                                                <label className='mx-3-medical'>Past Medical Issue </label>
                                                <input type="checkbox" id='On-Medication' onChange={e=>setOnMeditation(!onMedication)} checked={onMedication}/>
                                                <label className='mx-3-medical'>On Medication </label>
                                            </div>
                                        </div>
                                        {/* <div className="form-group">
                                            <label>Upload Medical Reports</label>
                                            <div className='gap-3'>
                                                <input type={'file'}/>
                                                
                                            </div>
                                        </div> */}
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Remark </label>
                                            <input
                                                type="text"
                                                maxLength={10}
                                                onChange={(e) => onInputChange(e)}
                                                value={short_description}
                                                name="short_description"
                                                className="form-control"
                                                placeholder="Enter remarks" />
                                        </div>
                                        <div className="form-group">
                                            <label>Booking Type</label>
                                            <div className='gap-3'>
                                                <input type="radio" name="bookingtype" onClick={e=>setBookingType("New")} checked={bookingType==="New"}/>
                                                <label className='mx-3-medical'>New </label>
                                                <input type="radio" name="bookingtype" onClick={e=>setBookingType("Followup")} checked={bookingType==="Followup"}/>
                                                <label className='mx-3-medical'>Followup </label>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <h4 className="bk_sub_title pt20">Bill details</h4>
                                    <div className="cons_fee">
                                        <span className="consl">
                                            Consultation fee
                                        </span>
                                        <span className="cons2"> &#x20B9;  {doctorFee}</span>
                                    </div>
                                    <div className="belowbtn">
                                        <p>
                                            1. Updates will be sent to {(isSomeOneElse === true) ? other_mobile : localStorage.getItem("user_mobile")}
                                        </p>
                                        {/* <p>2. By booking this appointment, you agree to FastTrack <span><a href="#">Terms and Conditions.</a></span></p> */}
                                        <p>
                                            <input type={'checkbox'} checked={isChecked} onClick={handleCheckBox} /><span> I agree to the <Link to={'#'}>Terms and Conditions.</Link></span>
                                        </p>
                                    </div>
                                    <div>
                                        <div className="text-center"><button disabled={isChecked ? false : true} type="submit" className="btn btn_fill btn-block" onClick={(e) => bookAndPay(e)}>Book &amp;  pay</button></div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BookingDetailsFillForm