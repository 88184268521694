import React, { useEffect } from 'react'
import Logo from "../../images/home/fasttrackLogo.svg"
import userAdmin from "../../images/adminpanel/userAdmin.jpg"
import userIcon from "../../images/adminpanel/UserIcon.svg"
import { Link, NavLink } from "react-router-dom"
import { useNavigate } from 'react-router';
import ProfileService from '../../services/ProfileServices';

const Header = () => {
    const navigateTo = useNavigate();
    
    const apifunction = new ProfileService();
    let profilePic = localStorage.getItem('profile_pic')
    let userId = localStorage.getItem('userid')
    let token=localStorage.getItem('token')
    let a = navigator.geolocation
    // console.log(a)
    console.log(profilePic,"profile")
    console.log(userId)
    // useEffect(() => {
    //     if (localStorage.getItem("user_type") === "" || localStorage.getItem("user_type") === undefined) {
    //         loadProfile()
    //     }
    // }, [])

    // const loadProfile = () => {
    //     if(token!==null && token!==undefined){
    //         console.log('name')
    //         apifunction.LoadProfileData().then(res => {
    //             console.log(res)
    //         })
    //     }
    // }
    // useEffect(()=>{
    //     if(profilePic == null && userId !== null){
    //         console.log('first')
    //         loadProfile()
    //     }
    // },[])
    const name = localStorage.getItem('user_name')
    if (name != null) {
        let ch = name.split("")
        for (let i = 0; i < name.length; i++) {

            if (i == 0 && ch[i] != ' ' ||
                ch[i] != ' ' && ch[i - 1] == ' ') {
                if (ch[i] >= 'a' && ch[i] <= 'z') {
                    ch[i] = String.fromCharCode(ch[i].charCodeAt(0) - 'a'.charCodeAt(0) + 'A'.charCodeAt(0));
                }
            }
            else if (ch[i] >= 'A' && ch[i] <= 'Z')
                ch[i] = String.fromCharCode(ch[i].charCodeAt(0) + 'a'.charCodeAt(0) - 'A'.charCodeAt(0));
        }
        let st = (ch).join("");
        let string = st.replace(/\s+/g, ' ').trim()
        localStorage.setItem('user_name', string)
    }

    const onClickLogOut = (e) => {
        localStorage.clear();
        sessionStorage.clear();
        navigateTo('/')
    }
    //  
    return (
        <header>
            <nav className="navbar-default navbar-fixed-top">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#navbar" aria-expanded="false"
                            aria-controls="navbar">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        <Link className="navbar-brand" to="/">
                            <img src={Logo} className="logo-icon" alt="FastTrack Logo" />
                            <span className="logo-text" />
                        </Link>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse navbar-links">
                        <ul className="nav navbar-nav nav-right">
                            <li className={location.pathname === "/tele-consultation" ? "link-li active" : "link-li"} ><NavLink to="/tele-consultation">Teleconsultation</NavLink></li>
                            <li className={location.pathname === "/homecare" ? "link-li active" : "link-li"} ><NavLink to="/homecare">Home care</NavLink></li>
                            <li className={location.pathname === "/ambulance" ? "link-li active" : "link-li"} ><NavLink to="/ambulance">Ambulance</NavLink></li>
                            <li className={location.pathname === "/contact-us" ? "link-li active" : "link-li"}><NavLink to={"/contact-us"}>Contact Us</NavLink></li>

                            {localStorage.getItem("token")
                                ?
                                <li className="dropdown user user-menu mlspace">
                                    <button to="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                        <img src={(profilePic != null && profilePic!="null") ? profilePic : userIcon} className="user-image" alt="User Image" />
                                        <span className="username">{localStorage.getItem("user_name")}</span>
                                        <span className="caret" />
                                    </button>
                                    <ul className="dropdown-menu userdropdown">
                                        <li>
                                            <Link to="/my-dashboard">My Account</Link>
                                        </li>                                       
                                        <li className="logout">
                                            <a onClick={onClickLogOut}>Logout</a>
                                        </li>
                                    </ul>
                                </li>
                                :
                                <>
                                    <li className="link-btn btn1"><Link to="/partner-with-us" className="btn btn_fill">Partner with us</Link></li>
                                    <li className='link-btn btn1'>
                                        <Link to="/login" className="btn btn_outline">Login / Signup</Link>
                                    </li>
                                </>
                            }
                           {/* {(localStorage.getItem("token"))?<li className='link-li ml-3'><NavLink><i className='fa fa-cog'></i> </NavLink></li>:""} */}
                        </ul>

                    </div>
                </div>
            </nav>
        </header>
    )
}

export default React.memo(Header);

