import axios from 'axios';
import ApiError from './ApiError';
const DEBUG = Boolean(process.env.REACT_APP_DEBUG === "1");
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = localStorage.getItem("token")

class APIHandler {
  //Get
  async get(uri, hasToken = true) {
    try {
      const apiResponse = await axios.get(`${baseUrl}${uri}`, getConfig(hasToken));
      return execute(apiResponse);
    }
    catch (err) {
      throw new ApiError(500, 'Something went wrong');
    }
  }
}

function getConfig(hasToken) {
  const config = {
    headers: {}
  };

  if (hasToken)
    config.headers.authorization = token;

  return config;
}

function execute(apiResponse) {
  if (!apiResponse.data.success) {
    if (DEBUG)
      console.error(apiResponse.data.message);
    throw new ApiError(apiResponse.status, apiResponse.data.message)
  }

  if (DEBUG)
    console.log(apiResponse.data);
  return apiResponse.data;
}

export default new APIHandler();