import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import BookingServices from '../../services/BookingServices'

const PaymentSuccessFail = () => {
    const navigate = useNavigate()
    const apiFunction = new BookingServices();
    const { orderid } = useParams()
    const [isPaymentSuccess, setIsPaymentSuccess] = useState("")
    const [secondCount, setSecondCount] = useState(5)


    useEffect(() => {
        paymentCapture()
        const interval = setInterval(() => {
            setSecondCount(secondCount => secondCount - 1)
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        navigate("/accounts-bookings", { state: { status: isPaymentSuccess } })
        window.location.reload();
    }, [secondCount === 0])



    // Want to confirm Payment this api will tell us Payment confirm or not
    const paymentCapture = () => {
        const data = {
            order_id: orderid,
            amount: localStorage.getItem("paymentAmount"),
            booking_id: localStorage.getItem("BookingId"),
            transaction_id: ''
        }
        apiFunction.PaymentCapture(data).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    setIsPaymentSuccess("true")
             
                } else {
                    setIsPaymentSuccess("false")
                }
            }
        })
    }

    return (
        <div className='paytmStatus'>
            {isPaymentSuccess === "" ?
                <h1 className='text-center'>Confirming Your Payment...</h1>
                :
                isPaymentSuccess === "true" ?
                    <>
                        <h1 className='text-center'>Payment Success</h1>
                        <h4 className= 'text-center'>Redirecting in {secondCount} seconds </h4>
                    </>
                    :
                    <>
                        <h1 className='text-center'>Payment Failed</h1>
                        <h4  className='text-center'>Redirecting in {secondCount} seconds </h4>
                    </>
            }
        </div>
    )
}

export default PaymentSuccessFail