import axios from 'axios';
import * as process from "process";
import { toast } from 'react-toastify';



// Routes define here 
// descrption:- last updated 15-06-22
// descrption:- By Mayur
class HomePageServices {
    constructor() {
        this.state = {
            ApibaseURL: process.env.REACT_APP_API_BASE_URL,
            AccessToken: localStorage.getItem('token'),
            Email: localStorage.getItem('Email'),
            ClientID: Number(localStorage.getItem('ClientID')),
            items: []
        }
    }


    // First this api will be work on Home Page and ShowSlotsProfileAppoinment 
    // but now work on only one page that is ShowSlotsProfileAppoinment
    async SearchPublicPage(searchItem,type,search_keyCity) {
        
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            let path="search/doctors";
            if(type=="Doctor"){
                path="search/doctors"
            }else if(type=="Nurse"){
                path="homecares/nurses"
            }else if(type=="Ambulance"){
                path="homecares/nurses"
            }
            console.log(`${this.state.ApibaseURL+path+"?search_key=" + searchItem+"&location="+search_keyCity}`)
            const response = await axios.get(`${this.state.ApibaseURL+path+"?search_key=" + searchItem+"&location="+search_keyCity}`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }


    async SendAppLink(data) {
    

        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/auth/sendlink`, data, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }
}
export default HomePageServices;
