import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Header from '../../components/headers/Header'
import Sidebar from '../../components/user/Sidebar'
import { regEmailValidation, regHtml, regMobile } from '../../helpers/Validate';
import {
    err_10DigitMobile,
    err_NotCity,
    err_NotCountry,
    err_NotDistrict,
    err_NotGender,
    err_NotHouseNo,
    err_NotMobile, err_NotName,
    err_NotSixDigitPinCode, err_NotState,
    err_NotStreetColony,
    err_NotValidEmail, HtmlErrorMessage, uploadImageFor
} from '../../enums/Enums';
import { ProfileService, UploadImageFor } from '../../services'
import { Specialization } from '../../services/DoctorOnboarding';
import { Link } from 'react-router-dom';
import DoctorProfile from '../../components/AggregatorComponents/DoctorProfile';
import axios from 'axios';
import Applydoctor from '../ApplyDoctor';
const baseurl = process.env.REACT_APP_API_BASE_URL
const RAPIDAPI_KEY = process.env.RAPIDAPI_KEY

const ProfileAll = () => {
    let token = localStorage.getItem('token')
    const apiFunction = new ProfileService()
    const [specializationList, setSpecializationList] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [availabilityList, setAvailabilityList] = useState([])
    const [sliceValue, setSliceValue] = useState(5)
    const [check, setCheck] = useState(true)
    const [gen, setGen] = useState('Male')
    const [status, setStatus] = useState('profile')
    const [istab, setistab] = useState(false)
    const [stateList, setStateList] = useState([])
    const [allcity, setallcity] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [cityid, setcityid] = useState("")
    const [profilepath, setprofilepath] = useState("")
    const [allergy, setAllergy] = useState(false)
    const [onMedication, setOnMeditation] = useState(false)
    const [pastMedicalIssue, setPastMedicalIssue] = useState(false)

    const [userProfileData, setUserProfileData] = useState({
        name: '',
        email: '',
        mobile: '',
        state: '',
        city: '1',
        gender: gen,
        zipcode: '',
        complete_address: '',
        medical_history: null,
        notes: '',
        is_applied_for_doctor: 0,
        profile_pic: "",
        dob:''


    })
    let { name, email, mobile, state, city, zipcode, gender, complete_address, medical_history, notes, is_applied_for_doctor, profile_pic,dob} = userProfileData
    const [userProfilePicture, setUserProfilePicture] = useState("")

    const onInputChange = (e) => {
            setUserProfileData({ ...userProfileData, [e.target.name]: e.target.value })
    }
    const [service, setservice] = useState(JSON.parse(localStorage.getItem("services")) || [])
    const isDoctor = service.find(s => s.service === "doctor");
    const isHomeCare = service.find(s => s.service === "nurse");
    const isAmbulance = service.find(s => s.service === "ambulance");

    const masterSpecialization = () => {
        try {
            Specialization(token)
                .then((res) => {
                    setSpecializationList(res.data)
                })
                .catch();

        } catch (error) {
        }

    }


    useEffect(() => {
        masterSpecialization();
        loadUserProfileData();
        stateAPI();

    }, [])
    useEffect(() => {
        cityAPI();
    }, [state, cityid])

    const loadUserProfileData = async () => {
        try {

            var config = {

                headers: {
                    'authorization': token
                }
            };
            const result = await axios.get(`${baseurl}users/me`, config)

            setUserProfilePicture(result.data.data.profile_pic)
            setGen(result.data.data.gender)
            setUserProfileData(result.data.data)
        } catch (error) {
            toast.error(error)
        }
    }

    const hr = []
    for (let i = 1; i <= 24; i++) {
        hr.push(i)
    }
    const stateAPI = async () => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': token
            }
        }
        const response = await axios.get(`${baseurl}states`, headers)
        setStateList(response.data.data)


    }
    const cityAPI = async () => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': token
            }
        }
        const citydata = await axios.get(`${baseurl}states/${cityid}/cities`)
        setallcity(citydata.data.data)

    }
    useEffect(() => {
        var newdata = userProfileData
        newdata.gender = gen
        setUserProfileData(newdata)


    }, [gen])

    useEffect(() => {
        var newdata = userProfileData
        newdata.allergy = allergy
        newdata.past_medical_issue=pastMedicalIssue
        newdata.on_medication=onMedication
        setUserProfileData(newdata)


    }, [allergy,pastMedicalIssue,onMedication])




    //put  for all  basic details
    const updateProfileOnsubmit = async (e) => {
        e.preventDefault();
        if (!name?.trim()) {
            toast.error(err_NotName)
        } else if (regHtml.test(name?.trim())) {
            return toast.error(HtmlErrorMessage)
        }
        else if (mobile.length !== 10) {
            return toast.error(err_10DigitMobile)
        }
        else if (email == '' || email == null) {
            return toast.error('Please Enter Email')
        }
        else if (!city) {
            return toast.error(err_NotCity)
        }
        else if (zipcode == '' || gender == null) {
            return toast.error('Please Enter Zip Code')
        }
        else if (regHtml.test(city?.trim())) {
            return toast.error(HtmlErrorMessage)
        } else {
            try {


                let id = localStorage.getItem('userid')
                const headers = {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': token
                    }
                }
                const response = await axios.put(`${baseurl}users/update`, userProfileData, headers)

                if (response != null) {
                    return toast.success("Profile successfully updated")

                }
            } catch (error) {
                toast.error(error)
            }




        }
    }


    const handleProfileImage = (type) => {
        if (type == 'profile') {
            let inPutClick = document.getElementById('profilePic')
            inPutClick.click()
        } else {
            let inPutClick = document.getElementById('signature')
            inPutClick.click()
        }
    }

    useEffect(() => {
        stateList.map((key) => {
            if (key.state_name == state) {
                setcityid(key.id)


            }

        })
    }, [state, cityid])


    //uploading profile picture--------------------------------------
    const uploadProfilePictur = (e) => {
        setIsLoading(true)
        const selectedFile = e.target.files[0];
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);
        UploadImageFor(formData, uploadImageFor.profile).then(result => {

            console.log(result, "profile pic")
            if (result !== null) {
                var temp = userProfileData;
                temp.profile_pic = result.data.filePath;
                setUserProfileData(temp)

                setprofilepath(result.data.filePath)
                var reader = new FileReader();
                reader.onload = function (e) {
                    setUserProfilePicture(e.target.result)
                };
                reader.readAsDataURL(selectedFile);

                // document.getElementById("showProfile").innerHTML = '<img src="' + e.target.result + '"/>';
            }
        })
    }



    return (
        <>
            <div class="outerhomepage">
                <Header />
                <section class=" paddbottom">
                    <div class="">
                        <div class="userpanel_page bg_light">
                            <Sidebar />
                            {/* <div className='width-35'>
                        </div> */}
                            <div class="right_panel">
                                <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                    <section class="content content-body">


                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="content-header">
                                                    <h1 class="pull-left">
                                                        Profile
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='spacetop'>
                                            <div className='row'>
                                                <div className='col-md-12' >
                                                    <div class="tab">
                                                        <button onClick={(e) => setStatus("profile")}
                                                            className={status === "profile" ? 'active' : ""}>Personal Details</button>

                                                        <button onClick={(e) => setStatus("doctor")}
                                                            className={status === "doctor" ? 'active' : ""}> Teleconsultation</button>


                                                        <button onClick={(e) => setStatus("homecare")}
                                                            className={status === "homecare" ? 'active' : ""}>Home Care</button>


                                                        <button onClick={() => setStatus("ambulance")}
                                                            className={status === "ambulance" ? 'active' : ""}>Ambulance</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            status === "profile" &&

                                            <div className='profile_body'>
                                                <h4 class="page_title">Basic Information</h4>
                                                <form>
                                                    <div class="row">
                                                        <div className='col-lg-10 col-md-10'>
                                                            <div className='row'>
                                                                <div class="col-lg-4">
                                                                    <div class="fd_input mb-2">
                                                                        <div class="form-group">
                                                                            <label >Name</label>
                                                                            <input type="text" value={name} name='name' onChange={(e) => onInputChange(e)} class="form-control" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4">
                                                                    <div class="fd_input mb-2">
                                                                        <div class="form-group">
                                                                            <label>Phone No</label>
                                                                            <input type="text" value={mobile} name='mobile' class="form-control" onChange={(e) => onInputChange(e)} placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4">
                                                                    <div class="fd_input">
                                                                        <div class="form-group">
                                                                            <label>Email</label>
                                                                            <input type="email" name='email' value={email} class="form-control" onChange={(e) => onInputChange(e)} placeholder="" />
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                                <div class="col-lg-4">
                                                                    <div class="fd_input aggregrator_decs ">
                                                                        <div class="form-group">
                                                                            <label>Gender</label>
                                                                            <div className="radioblock ">
                                                                                <div>
                                                                                    <input type="radio" id="test1" checked={gen === "Male" && check} onClick={(e) => setGen('Male')} name="radio-group" value={'Male'} />
                                                                                    <label htmlFor="test1">Male</label>
                                                                                </div>
                                                                                <div>
                                                                                    <input type="radio" id="test2" checked={gen === "Female" && check} onClick={(e) => setGen('Female')} name="radio-group" value={'Female'} />
                                                                                    <label htmlFor="test2">Female</label>
                                                                                </div>
                                                                                <div>
                                                                                    <input type="radio" id="test3" checked={gen === "Other" && check} onClick={(e) => setGen('Other')} name="radio-group" value={'Other'} />
                                                                                    <label htmlFor="test3">Other</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4">
                                                                    <div class="fd_input">
                                                                        <div class="form-group">
                                                                            <label>DOB</label>
                                                                            <input type="date" name='dob' value={dob} class="form-control" onChange={(e) => onInputChange(e)} placeholder=""/>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <div class="fd_input mb-2">
                                                                        <div class="form-group aggregrator_decs">
                                                                            <label for="gen">State</label>
                                                                            <select class="form-control" name='state'
                                                                                value={state} onChange={(e) => onInputChange(e)} >
                                                                                <option>Select State</option>
                                                                                {
                                                                                    stateList.map((val, ind) => {
                                                                                        return (
                                                                                            <option value={val.state_name}>{val.state_name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <div class="fd_input mb-2">
                                                                        <div class="form-group aggregrator_decs">
                                                                            <label for="gen">City</label>
                                                                            <select class="form-control" name='city'
                                                                                value={city} onChange={(e) => onInputChange(e)} >
                                                                                <option>Select City</option>
                                                                                {(allcity.length > 0 && allcity !== null) && allcity.map((key) => (
                                                                                    <option value={key.city_name}>{key.city_name}</option>

                                                                                ))}


                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <div class="fd_input mb-2">
                                                                        <div class="form-group aggregrator_decs">
                                                                            <label for="Specialization">Zip Code</label>
                                                                            <input type='text' class="form-control" value={zipcode} name='zipcode' id="sel1" onChange={(e) => onInputChange(e)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="fd_input">
                                                                        <div class="form-group">
                                                                            <label>Complete Address</label>
                                                                            <input type="email" name='complete_address' value={complete_address} class="form-control" onChange={(e) => onInputChange(e)} placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div class="col-lg-12">
                                                                    <div>
                                                                        <div className="form-group">
                                                                            <label>Medical History</label>
                                                                            <div className="radioblock ">
                                                                                <div>
                                                                                    <input type="checkbox" id='Allergies' onChange={e => setAllergy(!allergy)} checked={allergy} />
                                                                                    <label>Allergies </label>
                                                                                </div>
                                                                                <div>
                                                                                    <input type="checkbox" id='Past-Medical-Issue' onChange={e => setPastMedicalIssue(!pastMedicalIssue)} checked={pastMedicalIssue} />
                                                                                    <label>Past Medical Issue </label>
                                                                                </div>
                                                                                <div>
                                                                                    <input type="checkbox" id='On-Medication' onChange={e => setOnMeditation(!onMedication)} checked={onMedication} />
                                                                                    <label>On Medication </label>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div class="col-lg-12">
                                                                    <div class="fd_input">
                                                                        <div class="form-group">
                                                                            <label>Notes/Allergies/Past Medical Issue/On Medication ( If Any)</label>
                                                                            <textarea type="text" name='notes' value={notes} class="form-control" onChange={(e) => onInputChange(e)} placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-lg-2 col-md-2'>
                                                            <div className='row'>
                                                                <div className='col-lg-3'>
                                                                    <div class="fd_input mb-2">
                                                                        <div class="form-group profile-photo ">
                                                                            <div className='profile-photo-sig' id='showProfile' onClick={() => handleProfileImage('profile')}>
                                                                                {
                                                                                    <img style={{ 'z-indez': '11111' }} src={userProfilePicture} alt="Select Picture" />

                                                                                }
                                                                                {(userProfilePicture) ? "" : <p>Select Picture</p>}
                                                                            </div>
                                                                            <input id='profilePic' type={'file'} onChange={(e) => uploadProfilePictur(e)} />
                                                                            {/* <button className='btn btn-info'>Upload</button> */}
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="save_btn">
                                                        <a href="" class="btn btn_fill btn-xs" onClick={(e) => updateProfileOnsubmit(e)}>Save</a>
                                                    </div>
                                                </form>
                                            </div>

                                        }

                                        {
                                            status === "doctor" &&
                                            (
                                                (is_applied_for_doctor == 0 && !istab) ?

                                                    <div className='profile_body'>
                                                        <button className='btn btnprofile' onClick={(e) => setistab(!istab)}> Apply to provide Teleconsultation Service through the platform</button>

                                                    </div>
                                                    :
                                                    <div className='profile_body'> <DoctorProfile specializationList={specializationList} hr={hr} />
                                                    </div>
                                            )
                                        }
                                        {
                                            status === "homecare" &&
                                                (isHomeCare === undefined || isHomeCare === null) ?

                                                <div className='profile_body'>
                                                    <button className='btn btnprofile'><Link to="/apply-doctor"> Comming Soon</Link></button>
                                                </div>

                                                : ""

                                        }
                                        {
                                            status === "ambulance" &&
                                                (isAmbulance === undefined || isAmbulance === null) ?

                                                <div className='profile_body'>
                                                    <button className='btn btnprofile'><Link to="/apply-doctor"> Comming Soon</Link></button>
                                                </div>

                                                : ""

                                        }
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

export default ProfileAll