import React, { useEffect, useState } from 'react'
import { Footer, HomepageHeader } from '../../components';
import ContactBannerImg from "../../images/images/conatct_bnr.png"
import SendPng from "../../images/images/send.png"
import MapPinPng from "../../images/images/map-pin2.png"
import { regEmailValidation, regHtml, regMobile } from '../../helpers/Validate';
import { toast } from 'react-toastify';
import { ContactUsService } from '../../services';
import { HtmlErrorMessage } from '../../enums/Enums';


const ContactUs = () => {

    const apifunction = new ContactUsService()
    const [contactusForm, setContactusForm] = useState({
        concern_type: "",
        name: "",
        phone: "",
        email: "",
        concern_detail: ""
    })
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const { concern_type, name, phone, email, concern_detail } = contactusForm;

    const onInputChange = e => {
        setContactusForm({
            ...contactusForm, [e.target.name]:
                e.target.name === "phone" ?
                    // Check if not number it will return nothing only for mobile
                    e.target.value.match(regMobile) ? e.target.value : ""
                    : e.target.value
        })
    }

    const onContactFormSubmit = e => {
        e.preventDefault();
        if (!name) {
            return toast.error("Please enter name")
        }
        else if (regHtml.test(name?.trim())) {
            return toast.error(HtmlErrorMessage)
        }
        else if (!phone) {
            return toast.error("Please enter phone")
        }
        else if (email && !regEmailValidation.test(email?.trim())) {
            return toast.error("Please enter valid email")
        } else {
            apifunction.ContactUsFormSubmit(contactusForm).then(res => {
                if (res !== undefined) {
                    var result = res
                    if (result.success === true) {
                        setContactusForm({
                            concern_type: "",
                            name: "",
                            phone: "",
                            email: "",
                            concern_detail: ""
                        })
                        return toast.success("Your query stored by us, We will get back to you soon")
                    }
                }
            })
        }
    }


    return (
        <div className='outerhomepage'>
            <HomepageHeader />
            {/*Inner Page Banner*/}
            <section className="banner_inner">
                <div className="container">
                    <div className="card_flex">
                        <div className="card_text">
                            <h1>We are here to help</h1>
                            <h2>Let us know your concern and we will get in touch with you.</h2>
                        </div>
                        <div className="card_img">
                            <div className="dr_img-contect-us"><img src={ContactBannerImg} /></div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contact Form*/}
            <section className="contact_page paddtop paddbottom">
                <div className="container">
                    <div className="contact_body">
                        <div className="conatct_form">
                            <h3 className="heading2 text-center">Please provide details to contact you</h3>
                            <form onSubmit={onContactFormSubmit} className="form_top">

                                <div className="form-group">
                                    <label htmlFor="InputName">Name*</label>
                                    <input type="text" onChange={(e) => onInputChange(e)} value={name} name="name" className="form-control" id='InputName' placeholder="Name" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="InputPhone">Phone*</label>
                                    <input type="text" onChange={(e) => onInputChange(e)} value={phone} name="phone" className="form-control" maxLength={10} id='InputPhone' placeholder="Phone" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="InputEmail">Email (Optional)</label>
                                    <input type="text" onChange={(e) => onInputChange(e)} value={email} name="email" className="form-control" id="InputEmail" placeholder="Email" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Select your concern</label>
                                    <select onChange={(e) => onInputChange(e)} value={concern_type} name="concern_type" className="form-control">
                                        <option value="">Select your concern</option>
                                        <option value="Want to be a partner">Want to be a partner</option>
                                        <option value="Login Issue">Login issue</option>
                                        <option value="Payment Issue">Payment issue</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="InputExplainConcern">Explain your concern (Optional)</label>
                                    <textarea className="form-control" onChange={(e) => onInputChange(e)} value={concern_detail} name="concern_detail" id="InputExplainConcern" rows={5} placeholder="Explain your concern..." spellCheck="false" defaultValue={""} />
                                </div>
                                <div className="text-center"><button type="submit" className="btn btn_fill">Call me back</button></div>
                            </form>
                        </div>
                        <div className="form_footer paddtop">
                            <h3 className>Contact us</h3>
                            <p><img src={SendPng} /> Support@fasttrack.com</p>
                            <p><img src={MapPinPng} /> FastTrack,<br />  <span> 125, 24th Main Road, 3rd Cross, Mahmoorganj, HSR Layout, Varanasi – 221401</span>           </p>
                            <div className="mt40"><a href="#" className="btn btn_fill">Open in google maps</a></div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ContactUs;