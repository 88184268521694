import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import slide01 from "../../images/login/login_slide01.svg"
import slide02 from "../../images/login/login_slide02.svg"
import slide03 from "../../images/login/login_slide03.svg"
import 'react-phone-number-input/style.css'
import { err_10DigitMobile, err_NotEmailPassword, err_NotPassword, err_StartWithZeroMobile } from '../../enums/Enums'
import { regEmailValidation } from '../../helpers/Validate'
import { OnboardUser } from '../../services'
import { setLoggedIn, setLoggedOut } from '../../features/auth/authenticateSlice'
var reg = /^\d+$/;




// Routes define here 
// descrption:- last updated 25-06-22
// descrption:- By Mayur
const Login = () => {
    const navigate = useNavigate()
    const apifunction = new OnboardUser();
    const [mobileOrEmail, setMobileOrEmail] = useState("")
    const [is_professional, setIs_professional] = useState(false)
    const [ifShowPasswordField, setIfShowPasswordField] = useState(false)
    const [password, setPassword] = useState("")
    const [isMobileOrEmailDisable, setIsMobileOrEmailDisable] = useState(false)

    // let userId=localStorage.getItem('userid')
    // useEffect(()=>{
    //     if(userId!==null){
    //         console.log('first')
    //         navigate('/')
    //     }
    // })

    const loginSubmit = (e) => {
        e.preventDefault();
        const firstCharacter = mobileOrEmail[0]
        const firstThreeCharacter = mobileOrEmail.substring(0, 3)
        if (!mobileOrEmail?.trim()) {
            return toast.error("Please enter mobile or email")
        }
        else if (firstCharacter === "0" && mobileOrEmail?.trim()) {
            return toast.error(err_StartWithZeroMobile)
        }
        //if not Number
        else if (isNaN(Number(firstThreeCharacter))) {
            if (!regEmailValidation.test(mobileOrEmail?.trim())) {
                return toast.error("Enter valid email")
            } else {
                //Login with email
                sessionStorage.setItem("userLoginBy", "email");
                // setIsMobileOrEmailDisable(true);
                loginSubmitFunc(mobileOrEmail?.trim(), is_professional);

            }
        } else if (!isNaN(Number(firstThreeCharacter))) {
            if (mobileOrEmail?.trim().length !== 10) {
                return toast.error(err_10DigitMobile)
            } else {
                // Login with mobile
                sessionStorage.setItem("userLoginBy", "mobile");
                setIsMobileOrEmailDisable(true);
                loginSubmitFunc(mobileOrEmail?.trim(), is_professional);
            }
        }
    }

    useEffect(() => {
        setIfShowPasswordField(false)
    }, [mobileOrEmail])
    // Login Function
    const loginSubmitFunc = (mobilenumber, is_professional) => {
        const data = {
            mobile: mobilenumber,
            is_professional: is_professional
        }
        apifunction.LoginFunc(data).then(res => {
            if (res !== undefined) {
                var result = res
                console.log(result)
                if (result != null) {

                    localStorage.setItem("mobileOrEmail", mobilenumber)

                    if (result.data.completed_step >= 4) {
                        setIfShowPasswordField(true)
                    } else {
                        navigate("/validate-otp")
                        toast.success(result.message)
                        return true
                    }
                } else {
                    return false
                }
            }
            else {
                return false
            }
        })
    }

    // U
    const loginByPassword = (e) => {
        e.preventDefault();
        if (!mobileOrEmail) {
            toast.error(err_NotEmailPassword)
        }
        else if (!password) {
            toast.error(err_NotPassword)
        } else {
            const data = {
                mobile: mobileOrEmail,
                password: password
            }
            apifunction.loginByPassword(data).then(result => {
                console.log(result)
                if (result !== undefined) {
                    if (result.success === true) {
                        const profile_pic = result.data.user.profile_pic
                        localStorage.setItem("profile_pic", profile_pic)
                        console.log(profile_pic, "login")
                        // console.log(result.data.token)

                        let tokenUser = result.data.token
                        apifunction.getService(tokenUser).then(resp => {
                            console.log(resp, "services list")
                            if (result.success === true) {
                                localStorage.setItem("services", JSON.stringify(resp.data))
                                localStorage.setItem("usertype", JSON.stringify(resp.data))
                            }
                            console.log(resp.data)
                        })
                        window.$('#loginModalPopup').modal("hide");
                        const usersdata = result.data.user
                        const userid = usersdata.id
                        const mobileNumber = usersdata.mobile
                        const username = usersdata.name
                        const useremail = usersdata.email
                        const userType = usersdata.user_type
                        const userToken = result.data.token
                        // const profile_pic=usersdata.profile_pic
                        if (usersdata.completed_step === 4) {
                            localStorage.setItem("userid", userid)
                            localStorage.setItem("user_mobile", mobileNumber)
                            localStorage.setItem("token", userToken)
                            localStorage.setItem("user_name", username)
                            localStorage.setItem("user_email", useremail)
                            localStorage.setItem("user_type", userType)

                            const notLoggedInBookingFlowUrlDetails = sessionStorage.getItem('notLoggedInBookingFlowUrlDetails')
                            const userDetails = notLoggedInBookingFlowUrlDetails !== "" ? JSON.parse(notLoggedInBookingFlowUrlDetails) : ""
                            // console.log(sessionStorage.getItem('notLoggedInBookingFlowUrlDetails'),"--data")
                            if (userDetails) {
                                navigate('/booking-fill-form', {
                                    state: {

                                        slotidArr: userDetails.slotidArr,
                                        slotDate: userDetails.slotDate,
                                        slotTime: userDetails.slotTime,
                                        doctorName: userDetails.doctorName,
                                        doctorFee: userDetails.doctorFee,
                                        qualification: userDetails.qualification,
                                        specialization: userDetails.specialization,
                                        profileId: userDetails.profileId,
                                        profileType: userDetails.profileType,
                                        service_provider_id: userDetails.service_provider_id,
                                        experience: userDetails.experience
                                    }
                                })
                            } else {
                                if (result.data.services.length > 0)
                                    navigate('/providerdashboard')
                                else
                                    navigate('/my-dashboard')
                            }
                        } else {
                            localStorage.setItem("mobileOrEmail", mobileNumber)
                            navigate('/validate-otp')
                        }
                    }
                }
            })
        }
    }


    return (
        <div className="login_page">
            <div className="login_left">
                <div className="left_img">
                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                        {/* Indicators */}
                        <ol className="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to={0} className="active" />
                            <li data-target="#myCarousel" data-slide-to={1} />
                            <li data-target="#myCarousel" data-slide-to={2} />
                        </ol>
                        {/* Wrapper for slides */}
                        <div className="carousel-inner">
                            <div className="item active">
                                <img src={slide01} alt style={{ width: '100%' }} />
                            </div>
                            <div className="item">
                                <img src={slide02} alt style={{ width: '100%' }} />
                            </div>
                            <div className="item">
                                <img src={slide03} alt style={{ width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="login_right">
                {/* <h1>Login/Signup</h1> */}
                <form onSubmit={ifShowPasswordField && password?.trim() ? loginByPassword : loginSubmit}>
                    <div className="login_right_content">
                        <input type="text" value={mobileOrEmail} maxLength="255" onChange={(e) => setMobileOrEmail(e.target.value)} className="btn btn-block textBoxNumLogin" placeholder='Please enter mobile or email' />
                        {/* <div className="form-group checkbox_me">
                            <div className="form-checkbox">
                                <input type="checkbox" id="check" checked={is_professional} onChange={() => setIs_professional(!is_professional)} />
                                <label htmlFor="check">I’m a healthcare professional / service provider</label>
                            </div>
                        </div> */}

                        {(ifShowPasswordField) ?
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} maxLength="255" className="btn btn-block textBoxNumLogin" placeholder='Please enter password' />
                            : ""
                        }

                        <div className="loginbtn_box">
                            <div className>
                                <button type='submit' className="btn btn_fill  btn-block">Login / Signup</button>
                            </div>
                            {/* <div className="resetlink">Have an account ?<a href> Login</a></div> */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login