import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { HomepageHeader, SidebarUserPanel } from '../../components/index';
import moment from 'moment'
import { ServicesProviderService } from '../../services'
import { saveAs } from "file-saver";
import { ThreeDots } from 'react-loader-spinner'
import axios from 'axios';
import { Scheduler } from "@aldabil/react-scheduler";
import { Link } from 'react-router-dom';
const baseurl = process.env.REACT_APP_API_BASE_URL
const token = localStorage.getItem("token")
import { toast } from 'react-toastify';

import Eprecriptiondownload from '../E-prescription/downloadeprescription';
const UserDashBoard = () => {

    const apiFunction = new ServicesProviderService()
    const service = JSON.parse(localStorage.getItem("services"));
    const [statuschng, setstatuschng] = useState("Cancelled")
    const [type, setType] = useState("user");
    const date = "2022-08-10"//moment(new Date()).format("YYYY-MM-DD")
    const [dashboard, setDashboard] = useState([]);
    const [users, setUsers] = useState([]);
    const [EVENTS, setEvents] = useState([])
    const [todayappointment, settodayappointment] = useState([])
    const [recentPrescription, setrecentPrescription] = useState([])
    const [recentReports, setrecentReports] = useState([])
    const [upcomingBookings, setupcomingBookings] = useState([])
    const [isLoaded, setIsLoaded] = useState(true)
    const [prescriptionid, setPrescriptionid] = useState("");

    // useEffect(() => {
    //     if (service && service.length > 0) {
    //         setType(service[0].service);
    //     }
    // }, [])
    useEffect(() => {
        if (type && type !== "") {
            loadDashboard();
        }

    }, [type])

    let todayDate = new Date()
    console.log(todayDate.getUTCDate() + "-" + (todayDate.getMonth() + 1) + "-" + todayDate.getUTCFullYear())
    let a = new Date((todayDate.getMonth() + 1) + "-" + (todayDate.getUTCDate() + 1) + "-" + todayDate.getUTCFullYear())
    console.log((moment(a).utc()).format(`DD/MM/YYYY`))
    let today = (moment(a).utc()).format(`DD/MM/YYYY`)

    let token = localStorage.getItem('token')
    console.log(token)
    const loadDashboard = () => {
        setIsLoaded(false)
        if (token !== null || token !== undefined) {
            apiFunction.loadDashboardApi(date, token).then(result => {
                console.log(result, "----result")
                if (result !== undefined) {
                    if (result.data.success === true) {
                        setDashboard(result.data)
                        settodayappointment(result.data.data.todayAppointment)
                        setrecentPrescription(result.data.data.recentPrescription)
                        setrecentReports(result.data.data.recentReports)
                        setupcomingBookings(result.data.data.upcomingBookings)
                        setIsLoaded(true)
                        // setUsers(result.data.users);

                    }
                }
            })
        }
    }
    console.log(dashboard, "setdatadashboard")
    console.log(todayappointment, "apont")


    const saveFile = (link) => {
        saveAs(
            link,
            "report.pdf"
        );
    };
    { console.log(upcomingBookings, "upcoming") }

    const bookingcancel = async (id) => {
        try {
            var data = {
                "status": statuschng
            };

            var config = {

                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const result = await axios.put(`${baseurl}bookings/changebookingstatus/${id}`, JSON.stringify(data), config)
            if (result != null && result !== undefined) {
                toast.success("Booking Cancelled Sucessfully")
                loadcarddata()


            }

        } catch (error) {
            console.log(error)
        }

    }



    return (
        <div className="outerhomepage">

            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <SidebarUserPanel />
                        <div className="right_panel table-responsive">

                            {/* <div style={{ marginBottom: "22px", marginTop: "10px" }}>
                                <div class="tab pull-right">
                                    {
                                        (service && service.length > 1) &&
                                        service.map((item) => {
                                           return<>
                                            {item.is_approved===1&&<button onClick={(e) => setType(item.service)}
                                                className={type === item.service ? 'active'
                                                    : ""}>
                                                {item.service}
                                            </button>}
                                           </> 
                                        })
                                    }
                                </div>
                            </div> */}
                            {isLoaded ?
                                <div class="content-wrapper" style={{ marginLeft: 0 }}>

                                    <section class="content content-body">

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="content-header">
                                                    <h1 class="pull-left">
                                                        Dashboard
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="spacetop">
                                            {/* Today's Appointments */}
                                            <h4 className='page_title'>Today's Appointments</h4>
                                            <div class="row ">
                                                {(todayappointment != null && todayappointment != undefined && todayappointment.length > 0) ?
                                                    (todayappointment != null) && todayappointment.map((key) => (<>
                                                        <div className='col-lg-4 '>
                                                            <div class="db_box">
                                                                <div className='db_header'>
                                                                    <h5>Teleconsultation</h5>
                                                                    <div className='dbh_right'>
                                                                        <p>#{key.id}</p>
                                                                        <div className='dropdown' >
                                                                            <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                            <ul class="dropdown-menu ">
                                                                                <li><Link to={`/bookings-summary/${key.id}`} state={{ type: type }}>Booking Info</Link></li>
                                                                                {
                                                                                    type === "user" &&
                                                                                    <>
                                                                                        <li><Link onClick={() => bookingcancel(key.id)}>Cancel</Link></li>
                                                                                        <li key={key.service_provider_id + "doctorsNursesAmbulances-list"}>
                                                                                            <Link to="/show-profile-and-slots"
                                                                                                state={{
                                                                                                    userid: key.provider_id,
                                                                                                    searchType: key.service_type
                                                                                                }}
                                                                                            >
                                                                                                Rescheduled
                                                                                            </Link>
                                                                                        </li>

                                                                                    </>
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='db_body'>
                                                                    <div class="infono">
                                                                        <div className='infpo_icon'> <i class="fa fa-user-circle iconclass" aria-hidden="true" ></i>  </div>
                                                                        <div className='info_text'>
                                                                            <h5>{key.name}</h5>
                                                                            <p>{key.specialization}, {key.city}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='db_footer'>
                                                                    <div className='date'>
                                                                        {(moment(key.appointment_date).utc()).format("MMM DD")}, {(moment(key.appointment_date).utc()).format("hh:mm a")}
                                                                    </div>

                                                                    <div >
                                                                        {(key.status.toLowerCase() !== "cancelled") ?
                                                                            ((key.is_confirmed === 1 && (moment(key.appointment_date).utc()).format("DD/MM/YYYY") === today) ?
                                                                                <Link to="/video-call" state={{ roomId: key.room_id }}>  <i className='fa fa-video'></i> Join Call</Link> :
                                                                                key.is_confirmed === 0 ? <a style={{color:"orange"}}>Pending</a> : <a style={{color:"green"}}>Confirmed </a>)
                                                                            : <div style={{color:"red"}}>Cancelled</div>}
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>)) : <p className='marginme'>"No Record Found"</p>}


                                            </div>

                                            {/* Recent Prescription */}
                                            <h4 className='page_title'>Recent Prescription</h4>
                                            <div class="row ">

                                                {(recentPrescription != undefined && recentPrescription != null && recentPrescription.length > 0) ?
                                                    (recentPrescription != undefined) && recentPrescription.map((key) => (
                                                        <div className='col-lg-4 '>
                                                            <div class="db_box">
                                                                <div className='db_header'>
                                                                    <h5>E-Prescription</h5>
                                                                    <div className='dbh_right'>
                                                                        <p>#{key.booking_id}</p>
                                                                        <span><a onClick={() => setPrescriptionid(key.id)}> <i class="fa fa-download" aria-hidden="true"></i></a></span>
                                                                    </div>
                                                                </div>
                                                                <div className='db_body'>
                                                                    <div class="infono">
                                                                        <div className='info_text'>
                                                                            <h5>{key.name}</h5>
                                                                            <p>{key.headline}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )) : <p className='marginme'>"No Record Found"</p>}



                                                

                                            </div>

                                            {/* Recent Reports */}
                                            <h4 className='page_title'>Recent Reports</h4>
                                            <div class="row ">

                                                {recentReports != undefined && recentReports != null && recentReports.length > 0 ?
                                                    (recentReports != undefined) && recentReports.map((key) => (
                                                        <div className='col-lg-3 col-md-4 '>
                                                            <div class="db_box">
                                                                <div className='db_header'>
                                                                    <h5>{key.report_name}</h5>
                                                                    <div className='dbh_right'>
                                                                        <span><a onClick={() => saveFile(key.attachment)}><i class="fa fa-download" aria-hidden="true"></i></a></span>
                                                                    </div>
                                                                </div>
                                                                <div className='db_body'>
                                                                    <div class="infono">
                                                                        <div className='info_text'>
                                                                            <p><div className='date'>{(moment(key.report_date).utc()).format("MMM DD")}, {(moment(key.report_date).utc()).format("hh:mm a")} </div> </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )) : <p className='marginme'>"No Record Found"</p>}

                                            </div>

                                            {/* Upcoming Bookings (Next 7 days) */}
                                            <h4 className='page_title'>Upcoming Bookings (Next 7 days)</h4>
                                            <div class="row ">
                                                {upcomingBookings != undefined && upcomingBookings != null && upcomingBookings.length > 0 ?
                                                    (upcomingBookings != undefined) && upcomingBookings.map((key) => (
                                                        <div className='col-lg-4 '>
                                                            <div class="db_box">
                                                                <div className='db_header'>
                                                                    <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                    <div className='dbh_right'>
                                                                        <p>#{key.id}</p>
                                                                        <div className='dropdown' >
                                                                            <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                            <ul class="dropdown-menu ">
                                                                                <li><Link to={`/bookings-summary/${key.id}`} state={{ type: type }}>Booking Info</Link></li>
                                                                                {
                                                                                    type === "user" &&
                                                                                    <>
                                                                                        <li><Link onClick={() => bookingcancel(key.id)}>Cancel</Link></li>
                                                                                        <li key={key.service_provider_id + "doctorsNursesAmbulances-list"}>
                                                                                            <Link to="/show-profile-and-slots"
                                                                                                state={{
                                                                                                    userid: key.provider_id,
                                                                                                    searchType: key.service_type
                                                                                                }}
                                                                                            >
                                                                                                Rescheduled
                                                                                            </Link>
                                                                                        </li>

                                                                                    </>
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='db_body'>
                                                                    <div class="infono">
                                                                        <div className='infpo_icon'> <i class="fa fa-user-circle iconclass" aria-hidden="true" ></i>  </div>
                                                                        <div className='info_text'>
                                                                            <h5>{key.name}</h5>
                                                                            <p>{key.headline}, {key.city}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='db_footer'>
                                                                    <div className='date'><div className='date'>{(moment(key.appointment_date).utc()).format("MMM DD")}, {(moment(key.appointment_date).utc()).format("hh:mm a")} </div> </div>
                                                                    <div>
                                                                        {(key.status.toLowerCase() !== "cancelled") ?
                                                                            ((key.is_confirmed === 1 && (moment(key.appointment_date).utc()).format("DD/MM/YYYY") === today) ?
                                                                                <Link to="/video-call" state={{ roomId: key.room_id }}> <i className='fa fa-video'></i>  Join Call</Link> :
                                                                                key.is_confirmed === 0 ? <a style={{color:"orange"}}>Pending</a> : <a style={{color:"green"}}>Confirmed </a>)
                                                                            : <button style={{color:"red"}}>Rejected</button>}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )) : <p className='marginme'>"No Record Found"</p>}



                                            </div>

                                        </div>
                                    </section>

                                </div> :
                                <div class="defaultloader">
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color="#056B66"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    /></div>}
                        </div>
                    </div>
                </div>
                {prescriptionid !== undefined && prescriptionid !== null && prescriptionid !== "" ?
                                                    <div id="capture">
                                                        <Eprecriptiondownload prescriptionid={prescriptionid} setPrescriptionid={setPrescriptionid}></Eprecriptiondownload>
                                                    </div>
                                                    : ""
                                                }
            </section>

            {/* View Profile --- Doctor Homecare Ambulance */}


           
        </div>
    )
}

export default UserDashBoard;