import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import { useLocation } from 'react-router-dom';
import AdminSidebar from '../AdminSidebar';
import { DoctorApproval } from '../../../services/AdminServices/DoctorServices'
import { Identity } from '@mui/base';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';

const DoctorViewProfileDetails = ({ type, setType, service }) => {
    const [doctorData, setDoctorData] = useState([])
    const [showProfile, setShowProfile] = useState(true)
    const [showService, setShowService] = useState(false)
    const [showAvailability, setShowAvailability] = useState(false)
    const [showDocuments, setShowDocuments] = useState(false)
    const [status, setStatus] = useState('profile')
    const navigateTo = useNavigate()
    const locationData = useLocation().state
    const [details, setDetails] = useState(locationData)
    const [statusShow, setStatusShow] = useState()
    useEffect(()=>{
        setStatusShow(locationData.statusShow)
    },[])
    console.log(locationData)

    const handleDoctorType = (statusValue) => {
        setStatus(statusValue)
        if (statusValue === 'profile') {
            setShowProfile(true)
            setShowService(false)
            setShowAvailability(false)
            setShowDocuments(false)
        } else if (statusValue === 'services') {
            setShowProfile(false)
            setShowService(true)
            setShowAvailability(false)
            setShowDocuments(false)
        } else if (statusValue === 'availability') {
            setShowProfile(false)
            setShowService(false)
            setShowAvailability(true)
            setShowDocuments(false)
        } else if (statusValue === 'documents') {
            setShowProfile(false)
            setShowService(false)
            setShowAvailability(false)
            setShowDocuments(true)
        }
    }
    const handleProfileStatus = (id, status) => {
        try {
            let service = 'Doctor'
            DoctorApproval(id, service, status)
                .then((res) => {
                    console.log(res)
                    if (res.success === true) {
                        toast.success(res.message)
                        navigateTo('/doctor-Application-details')
                    }
                    //   setDoctorData(res.data)
                })
                .catch();
        } catch (error) { }
    }
    return (
        <div className="outerhomepage">

            {/*Top Navbar Menu*/}
            {/* <HomepageHeader /> */}
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <AdminSidebar />
                        <div className="right_panel table-responsive" style={{ marginTop: "20px" }}>
                            <div className='col-md-12' style={{ marginBottom: "22px" }}>
                                <div class="tab pull-right">
                                    {
                                        service &&
                                        service.map((item) => (
                                            <button onClick={(e) => setType(item.service)}
                                                className={type === item.service ? 'active'
                                                    : ""}>
                                                {item.service}
                                            </button>
                                        ))

                                    }
                                </div>

                            </div>
                            <h2>User Management</h2>
                            <div className='col-md-8' style={{ marginTop: "22px" }}>
                                <div class="tab col-md-12">
                                    <button onClick={() => handleDoctorType('profile')}
                                        className={status === "profile" ? 'active'
                                            : ""}>
                                        Profile
                                    </button>
                                    <button onClick={() => handleDoctorType('services')}
                                        className={status === "services" ? 'active' : ""}>specialization</button>
                                    <button onClick={() => handleDoctorType('availability')}
                                        className={status === "availability" ? 'active' : ""}>Availability</button>
                                    <button onClick={() => handleDoctorType('documents')}
                                        className={status === "documents" ? 'active' : ""}>Documents</button>
                                </div>
                            </div>
                            <div className='justify-content-md-center ms-5'>
                                <div className='col-md-10 margin-top'>
                                    <div>
                                        {
                                            showProfile && 
                                                <table className='table-details-align'>
                                                    <tr>
                                                        <th>Name</th>
                                                        <td>{details.profileData.profile.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Mobile</th>
                                                        <td>{details.profileData.profile.mobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email</th>
                                                        <td>{details.profileData.profile.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Address</th>
                                                        <td>{details.profileData.profile.address}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>city</th>
                                                        <td>{details.profileData.profile.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>state</th>
                                                        <td>{details.profileData.profile.state}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>country</th>
                                                        <td>{details.profileData.profile.country}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Zip Code</th>
                                                        <td>{details.profileData.profile.pincode}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Experience</th>
                                                        <td>{details.profileData.profile.experience}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Qualification</th>
                                                        <td>{details.profileData.profile.qualification}</td>
                                                    </tr>
                                                </table>
                                        }
                                        {
                                            showService &&
                                            <>
                                                <h4>specialization</h4>
                                                {
                                                    details.profileData.specialization.map((val, ind) => {
                                                        return (
                                                            <div key={ind}>
                                                                <img src={val.banner_image} />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <span>{val.type}</span>
                                                            </div>
                                                        )
                                                    })

                                                }
                                            </>
                                        }
                                        {
                                            (showAvailability) && 
                                            // (details.profileData.availability === []) ?
                                            //     <h4>No Availability found</h4> :
                                                details.profileData.availability.map((val,ind)=>{
                                                    return (
                                                        <table className='table-details-align'>
                                                    <tr>
                                                        <th>Day</th>
                                                        <td>{val.day}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Time</th>
                                                        <td>{val.start_time} - {val.end_time}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Price</th>
                                                        <td>{val.fee}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <th>Tele Consultancy Fee</th>
                                                        <td>{}</td>
                                                    </tr> */}
                                                </table>
                                                    )
                                                })

                                        }
                                        {
                                            showDocuments && details.profileData.document.map((val, ind) => {
                                                return (
                                                    val.map((value,key)=>{
                                                        return (
                                                            <table className='table-details-align'>
                                                    <tr>
                                                        <th>medical_registration</th>
                                                        <td><img src={value.medical_registration} /></td>
                                                    </tr>
                                                </table>
                                                            
                                                        )
                                                    })
                                                    
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        statusShow === 'pending' &&
                                        <div className='padding-top col-md-12'>
                                            <button className='btn btn-success btn-sm' onClick={() => handleProfileStatus(details.profileData.profile.doctor_id, 'true')}>Accept</button>
                                            <button className='btn btn-danger btn-sm ms-5' onClick={() => handleProfileStatus(details.profileData.profile.doctor_id, 'false')}>Reject</button>
                                        </div>
                                    }
                                    {
                                        statusShow === 'active' &&
                                        <div className='padding-top col-md-12'>
                                            <button className='btn btn-danger btn-sm ms-5'>Inactive</button>
                                        </div>
                                    }
                                    {
                                        statusShow === 'block' &&
                                        <div className='padding-top col-md-12'>
                                            <button className='btn btn-success btn-sm'>Active</button>
                                        </div>
                                    }
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section >


        </div >
    )
}

export default DoctorViewProfileDetails;