import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { HomepageHeader, SidebarUserPanel } from '../';
import moment from 'moment'
import { ProfileAndSlotService } from '../../services';
import { ShowProfileType } from '../../enums/Enums';
import CheckCircle from "../../images/images/check-circle.png"
import userIcon from "../../images/adminpanel/UserIcon.svg"
import ServicesProviderStarter from '../../pages/privatePages/ServicesProviderStarter';


const AggregatorBookingComponent = ({ selfDetails, data, isloading, setStatus, status,type,setType,service }) => {

    const apiFunction = new ProfileAndSlotService();
    const [showAddPrescription, setShowAddPrescription] = useState(false)
    const pagination = useRef();
    const [offset, setOffset] = useState(0);
    const perPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(1);
    // const [serviceProviderId, setServiceProviderId] = useState(0);
    
    const [searchVal, setSearchVal] = useState("");
    const [paginateList, setPaginateList] = useState([])
    const [userData, setUserdata] = useState({
        username: "",
        bio: "",
        profile_pic: "",
        qualification: "",
        specialization: "",
        experience: "",
        consultation_fees: "",
        video_consult: "",
        slots: [],
        patientStories: []
    })
    // console.log(service)
    const filterDataBySearch = () => {
        let dataArr = data.filter(item =>
            item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            item.service_type.toLowerCase().includes(searchVal.toLowerCase())
        )
        setTotalData(dataArr.length)
        if (dataArr.length > 0) {
            const slice = dataArr.slice(offset, offset + perPage);
            setPaginateList(slice);
            setPageCount(Math.ceil(dataArr.length / perPage));
        } else {
            setPaginateList([]);
        }
    }


    // Debounce function
    const debounce = (fn, delay) => {
        let timer;
        return (() => {
            clearTimeout(timer);
            timer = setTimeout(() => fn(), delay);
        })();//ifee-->  Immediately Invoked Function Expression
    };
   
    useEffect(() => {
        if (searchVal) {
            debounce(filterDataBySearch, 1000);
        }
        else {
            loadData();
        }

    }, [searchVal])


    const loadData = () => {
        let dataArr = data
        setTotalData(dataArr.length)
        if (dataArr.length > 0) {
            const slice = dataArr.slice(offset, offset + perPage);
            setPaginateList(slice);
            setPageCount(Math.ceil(dataArr.length / perPage));
        } else {
            setPaginateList([]);
        }
    }

    useEffect(() => {
        loadData()
    }, [number, offset, data])

    const setPage = ({ selected }) => {
        setNumber(selected)
        setOffset(perPage * selected)
    }


    const loadServiceProviderProfile = (userid, serviceType) => {
        if (serviceType === ShowProfileType.type1) {
            apiFunction.LoadDoctorProfileAndSlots(userid).then(result => {
                if (result !== undefined) {

                    if (result.success === true) {
                        setUserdata({
                            username: result.data.doctor.name,
                            bio: result.data.doctor.bio,
                            profile_pic: result.data.doctor.profile_pic,
                            qualification: result.data.doctor.qualification,
                            specialization: result.data.doctor.specialization,
                            experience: result.data.doctor.experience,
                            consultation_fees: result.data.doctor.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }

            })
        } else if (serviceType === ShowProfileType.type2) {
            apiFunction.LoadNursesProfileAndSlots(userid).then(result => {
                if (result !== undefined) {
                    if (result.success === true) {
                        setUserdata({
                            username: result.data.nurse.name,
                            bio: result.data.nurse.bio,
                            profile_pic: result.data.nurse.profile_pic,
                            qualification: result.data.nurse.qualification,
                            specialization: result.data.nurse.specialization,
                            experience: result.data.nurse.experience,
                            consultation_fees: result.data.nurse.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }

            })

        }
        else if (serviceType === ShowProfileType.type3) {
            apiFunction.LoadAmbulancesProfileAndSlots(userid).then(result => {
                if (result !== undefined) {
                    if (result.success === true) {
                        setUserdata({
                            username: result.data.ambulance.name,
                            bio: result.data.ambulance.bio,
                            profile_pic: result.data.ambulance.profile_pic,
                            qualification: result.data.ambulance.qualification,
                            specialization: result.data.ambulance.specialization,
                            experience: result.data.ambulance.experience,
                            consultation_fees: result.data.ambulance.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }
            })
        }

    }



    return (
        <div className="outerhomepage">

            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <SidebarUserPanel />
                        {
                            service===undefined? <ServicesProviderStarter/> :<div className="right_panel table-responsive" style={{ marginTop: "20px" }}>
                            <div className='col-md-12' style={{ marginBottom: "22px" }}>
                                <div class="tab pull-right">
                                    {
                                        service &&
                                        service.map((item) => (
                                            <button onClick={(e) => setType(item.service)}
                                                className={type === item.service ? 'active'
                                                    : ""}>
                                                {item.service}
                                            </button>
                                        ))

                                    }
                                </div>

                            </div>
                            <div className='col-md-6' style={{ marginTop: "22px" }}>
                                <div class="tab col-md-12">
                                    <button onClick={() => setStatus("Booked")}
                                        className={status === "Booked" ? 'active'
                                            : ""}>
                                        Active
                                    </button>
                                    <button onClick={() => setStatus("Completed")}
                                        className={status === "Completed" ? 'active' : ""}>Completed</button>
                                    <button onClick={() => setStatus("Canceled")}
                                        className={status === "Canceled" ? 'active' : ""}>Canceled</button>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <label>Search</label>
                                <input type="text" value={searchVal} onChange={(e) => setSearchVal(e.target.value)} className='form-control' placeholder='Search...' />
                            </div>
                            <table className="table mytable">
                                <tbody>
                                    <tr>
                                        <th style={{ width: '12.5%' }}>Booking Id</th>
                                        <th style={{ width: '12.5%' }}>Date/Time</th>
                                        <th style={{ width: '12.5%' }}>{selfDetails ? "Name" : "Patient Details"}</th>
                                        <th style={{ width: '12.5%' }}> {selfDetails ? "Service Type" : "For"}</th>
                                        <th style={{ width: '12.5%' }}>Appointment Date</th>
                                        <th style={{ width: '12.5%' }}>Fee</th>
                                        <th style={{ width: '12.5%' }}>View</th>
                                        <th style={{ width: '12.5%' }}></th>
                                    </tr>
                                    {paginateList.length === 0 ? <tr><td colSpan={8}>No records found</td></tr> :
                                        paginateList.map(item =>
                                        (
                                            <tr>
                                                <td>
                                                    <div className="userdtl">
                                                        <div className="userinfo">
                                                            {item.id}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="bookedat">
                                                        <p>{(moment(item.booking_date).utc()).format("DD/MM/YYYY")} </p>
                                                        <span>
                                                            {(moment(item.booking_date).utc()).format("hh:mm a")}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {item.service_type
                                                    }
                                                </td>
                                                <td>
                                                    <div className="bookedat">
                                                        <p>{(moment(item.appoinment_date).utc()).format("DD/MM/YYYY")} </p>
                                                        <span>
                                                            {(moment(item.appoinment_date).utc()).format("hh:mm a")}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amount_td">
                                                        <div>
                                                            <p>&#x20B9; {item.total_amount}</p><span>{item.status}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {selfDetails ?
                                                        <button type="button" onClick={() => loadServiceProviderProfile(item.service_provider_id, item.service_type)} class="btn btn-info btn-sm" data-toggle="modal" data-target="#viewServiceProviderDetailsPopup">View</button>
                                                        :
                                                        <button type="button" class="btn btn-info btn-sm" data-toggle="modal" data-target="#viewPatientDetailsPopup">View</button>
                                                    }
                                                </td>
                                                <td>
                                                    {selfDetails ? <>
                                                        {status !== "Completed" && <button type="button" class="btn btn-info btn-sm" >Cancel</button>}
                                                    </>

                                                        :
                                                        <button type="button" class="btn btn-info btn-sm" >Acknowledged</button>
                                                    }


                                                </td>
                                            </tr>
                                        )
                                        )}
                                </tbody>
                            </table>
                            <>
                                {totalData > 10 &&
                                    <div className='mypagination'>
                                        <ReactPaginate
                                            ref={pagination}
                                            pageCount={pageCount}
                                            pageRangeDisplayed={4}
                                            marginPagesDisplayed={0}
                                            onPageChange={setPage}
                                            activeClassName="active"
                                            containerClassName="pagination_ul"
                                            pageClassName="page-item"
                                            breakClassName="page-item"
                                            nextClassName="page-item"
                                            previousClassName="page-item"
                                            breakLabel="..."
                                            previousLabel={<>Prev</>}
                                            nextLabel={<>Next</>}
                                        />
                                    </div>
                                }
                            </>
                        </div>
                        }
                    </div>
                </div>
            </section >

            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}
            <div className="modal fade" id="viewServiceProviderDetailsPopup" role="dialog">
                <div className="modal-dialog" style={{ width: "100rem" }}>
                    {/* Modal content*/}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">×</button>
                            <h4 className="modal-title">Service Provider Details</h4>
                        </div>
                        <div className="modal-body">
                            <div className="col-lg-12">
                                {/*Doctor Info*/}
                                <div className="doctor_details bg_light">
                                    <div className="doctor_img">
                                        <div className="dr_photo"><img src={userIcon} alt="" /></div>
                                    </div>
                                    <div className="doctor_bio">
                                        {/* <button className="btn btn_fill" style={{ float: "right" }}>Rate & Review</button> */}
                                        <h3 className="dr_name">{userData.username}</h3 >
                                        {userData.specialization &&
                                            <p className="dr_quali">{userData.specialization}</p>
                                        }
                                        {userData.qualification &&
                                            <p className="dr_quali">{userData.qualification}</p>
                                        }
                                        {userData.experience &&
                                            <p className="dr_exp"><img src={CheckCircle} />&nbsp;&nbsp; {userData.experience} Years</p>
                                        }
                                        {/* <p className="dr_votes"><span><img src={ThumbPng} />&nbsp;&nbsp; 96% </span>(935 votes)</p> */}
                                        <div className="dr_desc">
                                            {userData.bio &&
                                                <p className="dr_quali">{userData.bio}</p>
                                            }
                                            {/* Dr. Manohar Saini is a senior consultant Orthopedics specialized in Joint Replacement and Arthroscopic Surgery. He attended KGMC, Lucknow and was trained in Ortho
                                            <span id="dots">...</span>
                                            <span id="more">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
                                            </span>
                                            <a onclick="ReadMore()" id="myBtn" style={{ color: '#056B66', cursor: 'pointer' }}>Read more</a> */}

                                        </div>
                                    </div>
                                </div>
                                {/*Patient Stories*/}
                                {userData.patientStories.length !== 0 &&
                                    <div className="patient_stories bg_light">
                                        <div className="pt_stories_header">
                                            <h3>Patient Stories</h3>
                                        </div>

                                        {userData.patientStories.length === 0 ?
                                            <h4 className='text-center'>No stories found</h4>
                                            :
                                            <>
                                                <div className="pt_stories_body">
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw1"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw2"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="happy_block">
                                                                    <p>Happy with:&nbsp; <span className="happy_tag">Treatment satisfaction</span> <span className="happy_tag">Value for money</span></p>
                                                                </div>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw1"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt_stories_footer">
                                                    <div className="text-center"><a href="#" className="btn btn_fill">View more</a></div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                }

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-theme radius">Acknowledged</button>
                            <button type="button" className="btn btn-default radius" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}
            <div className="modal fade" id="viewPatientDetailsPopup" role="dialog">
                <div className="modal-dialog" style={{ width: "100rem" }}>
                    {/* Modal content*/}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">×</button>
                            <h4 className="modal-title">Patient Details</h4>
                        </div>
                        <div className="modal-body">
                            <div className="patient_panel">
                                <div className="right_tab">
                                    <div className="patient_header">
                                        <div className="photo">
                                            <img src="images/ptimages.png" />
                                        </div>
                                        <div className="patient_detials">
                                            <h4 className="patient_name">Rabia Khan</h4>
                                            <div className="ptinfo">
                                                <h5><span>Gender:</span> F</h5>
                                                <h5><span>Blood group: </span>“O” +ve</h5>
                                            </div>
                                            <div className="ptinfo">
                                                <h5>Age:<span>27</span></h5>
                                                <h5>Mobile:<span>+91 4586749761</span></h5>
                                                <h5>Email:<span>rabia@khan.com</span></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="patient_body">
                                        <div className="appoint_schedule">
                                            <div className="dateblock">
                                                <div className="date"><span>26</span>&nbsp;Jan</div>
                                                <div className="datedtl">
                                                    <h5>Appointment scheduled at  10:30 AM</h5>
                                                    <p>Records for Vivek Singhal</p>
                                                </div>
                                            </div>
                                            {/* <div className="shareicon">
                                                <p><i className="fa fa-share-alt" /></p>
                                                <span>Follow-up</span>
                                            </div>
                                            <div className="shareicon">
                                                <p><i className="fa fa-share-alt" /></p>
                                                <span>Share</span>
                                            </div> */}
                                            <div className="radiusbtn">
                                                <div className="btn-group pull-right">
                                                    <button type="button" onClick={() => setShowAddPrescription(true)} className="btn btn-info">Add Record &nbsp;<i className="fa fa-plus" /></button>
                                                    <button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown">
                                                        <span className="caret" />
                                                        <span className="sr-only">Toggle Dropdown</span>
                                                    </button>
                                                    <ul className="dropdown-menu" role="menu">
                                                        <li><a href="patient.html">Clinical note</a></li>
                                                        <li><a href="prescription.html">Prescription</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {showAddPrescription ?
                                            <form className="note_form">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Clinical note" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Complaints" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Prescroption</label>
                                                    <textarea className="form-control" rows={5} placeholder spellCheck="false" defaultValue={""} />
                                                </div>
                                                <div className="btnblock">
                                                    <button type="button" onClick={() => setShowAddPrescription(false)} className="btn btn-themeoutline radius btnbig">Cancel</button>
                                                    <button type='button' className="btn btn-theme radius btnbig">Save</button>
                                                </div>
                                            </form>
                                            : ""
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-theme radius">Acknowledged</button>
                            <button type="button" className="btn btn-default radius" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default AggregatorBookingComponent;