import React, { useEffect, useState } from 'react'
import VideoCallDoctor from "../../images/home/ft_app.svg"
import Playstore from "../../images/home/playstore.png"
import {
    HomepageHeader,
    HomepageBanners,
    ServiceWeOfferComponent,
    AboutFasttrack,
    Footer
} from '../../components'
import { HomePageServices } from '../../services';
import { toast } from 'react-toastify';
import { err_10DigitMobile } from '../../enums/Enums';
// import { ProfileService } from '../../services'
var reg = /^\d+$/;

// descrption:- last updated 20-06-22
// descrption:- By Mayur

const Homepage = () => {
    const [mobile, setmobile] = useState("")
    const apifunction = new HomePageServices();
    // const apiFunction1 = new ProfileService()
    // let userId=localStorage.getItem('userid')
    // console.log(userId)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const sendSmsToMobile = (e) => {
        e.preventDefault();

        if (mobile.length !== 10) {
            return toast.error(err_10DigitMobile)
        } else {
            const data = {
                mobile: mobile
            }
            apifunction.SendAppLink(data).then(res => {
                if (res !== undefined) {
                    var result = res
                    if (result.success === true) {
                        setmobile("")
                        return toast.success(result.message)
                    }
                }
            })
        }
    }
    // useEffect(()=>{
    //     if(userId!==null){
    //         loadUserProfileData()
    //     }
    // })
    // const loadUserProfileData = () => {
    //     apiFunction1.LoadProfileData().then(result => {
    //         if (result.success === true) {
    //             localStorage.setItem('profile_pic',result.data.profile_pic)
    //         }
    //     })
    // }
    return (
        <div className="outerhomepage">
            <HomepageHeader />
            <HomepageBanners />
            <ServiceWeOfferComponent />
            <AboutFasttrack />

            {/* Download the FastTrack app  */}
            <section className="download_app">
                <div className="container">
                    <div className="download_wrapper">
                        <div className="app_content">
                            <h3 className="heading2"> Download the FastTrack app </h3>
                            <p className="para2">Access video consultation with India’s top doctors on the FastTrack app.<br /> Connect with doctors online, available 24/7, from the comfort of your<br /> home.</p>
                            <div className="get_link">
                                <h4>Get the link to download the app</h4>
                                <form className="enter_phone" onSubmit={(e) => sendSmsToMobile(e)}>
                                    <div className="input-group">
                                        <span className="input-group-addon">+91</span>
                                        <input type="text" value={mobile} maxLength="10" onChange={(e) => setmobile(e.target.value.match(reg) ? e.target.value : "")} className="form-control" placeholder="Enter the phone number" />
                                    </div>
                                    <button type='submit' className="btn btn_fill">Send SMS</button>
                                </form>
                                <a href="#"><img src={Playstore} /></a>
                            </div>
                        </div>
                        <div className="app_img">
                            <img src={VideoCallDoctor} />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            {/* Back to top button  */}
            <a id="Back-to-top"></a>
        </div>
    )
}

export default Homepage;