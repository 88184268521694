let ApibaseURL= process.env.REACT_APP_API_BASE_URL

export const OnePatientDetails = async (userId,id) => {
    try {
      const response = await fetch(`${ApibaseURL}/doctors/${userId}/patientprofile/${id}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'authorization': localStorage.getItem('token'),
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(),
      });
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
      }
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
      alert("Something went wrong , Please try again later.");
    }
  };