import moment from 'moment';
import { useEffect, useState } from 'react';
import HomepageHeader from '../../components/headers/Header';
import SidebarUserPanel from '../../components/user/Sidebar';
import userDashboardServices from '../../services/userDashboardServices';
import { ThreeDots } from 'react-loader-spinner'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Eprecriptiondownload from '../E-prescription/downloadeprescription';

const ProviderDashboard = () => {
    const apiFunction = new userDashboardServices()
    const [todayAppoinment, setTodayAppoinment] = useState([])
    const [overAllSummary, setOverAllSummary] = useState(null)
    const [sheduledForToday, setSheduledForToday] = useState(null)
    const [pendingForAcknowledge, setPendingForAcknowledge] = useState([])
    const [recentPrescription, setRecentPrescription] = useState([])
    const [recentReports, setRecentReports] = useState([])
    const [upcomingBookings, setUpcomingBookings] = useState([])
    const [isLoaded, setIsLoaded] = useState(true)
    const [prescriptionid, setPrescriptionid] = useState("");
    const token = localStorage.getItem("token")
    useEffect(() => {
        LoadDashboard()
    }, [])

    const LoadDashboard = async () => {
        var date = moment(new Date()).format("YYYY-MM-DD")
        setIsLoaded(false)
        apiFunction.DoctorDashboardApi(date, token).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    console.log(result.data, "----dashboard")
                    setTodayAppoinment(result.data.todayAppointment)
                    setOverAllSummary(result.data.overAllSummary)
                    setSheduledForToday(result.data.sheduledForToday)
                    setPendingForAcknowledge(result.data.PendingForAcknowledge)
                    setRecentPrescription(result.data.recentPrescription)
                    //setRecentReports(result.data.recentReports)
                    setUpcomingBookings(result.data.upcomingBookings)
                    setIsLoaded(true)

                }
            }
        })
    }
    function randomID(len) {
        let result = '';
        if (result) return result;
        var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
            maxPos = chars.length,
            i;
        len = len || 5;
        for (i = 0; i < len; i++) {
            result += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return result;
    }
    const AcceptRejectBooking = (bookingid, status) => {
        console.log("Rakesh Booking status by div ", bookingid, " status : ", status)
        const roomid = randomID(5)
        apiFunction.AcceptBookingApi(bookingid, status, roomid).then(result => {
            if (result !== undefined) {
                if (result.success === true) {

                    LoadDashboard()
                    if (status) {
                        toast.success("Booking successfully accepted.")
                    }
                    else {
                        toast.success("Booking successfully rejected.")
                    }


                }
            }
        })
    }


    let todayDate = new Date()
    console.log(todayDate.getUTCDate() + "-" + (todayDate.getMonth() + 1) + "-" + todayDate.getUTCFullYear())
    let a = new Date((todayDate.getMonth() + 1) + "-" + (todayDate.getUTCDate() + 1) + "-" + todayDate.getUTCFullYear())
    console.log((moment(a).utc()).format(`DD/MM/YYYY`))
    let today = (moment(a).utc()).format(`DD/MM/YYYY`)

    return (<>
        <div className='outerhomepage'>
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="userpanel_page bg_light">
                    {/* Side bar */}
                    <SidebarUserPanel />
                    {isLoaded ?
                        <div className="right_panel ">
                            <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                <div class="content content-body">
                                    {/* Page Title */}
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="content-header">
                                                <h1 class="pull-left">
                                                    Dashboard
                                                </h1>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Scheduled for Today */}
                                    <div class="row spacetop">
                                        {(sheduledForToday !== undefined && sheduledForToday !== null) &&
                                            <div className='col-lg-6'>
                                                <div class="db_box">
                                                    <div className='db_header mh51'>
                                                        <h5>Scheduled for Today</h5>
                                                        {/* <div className='dbh_right'>
                                                        <p>#817</p>
                                                        <span><a href='#'><i class="fa fa-download" aria-hidden="true"></i></a></span>
                                                    </div> */}
                                                    </div>
                                                    <div className='db_body'>
                                                        <div class="spd_card">
                                                            <div className='spd_card_list'>
                                                                <p> Scheduled</p>
                                                                <h4>{sheduledForToday.sheduled}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Completed</p>
                                                                <h4>{sheduledForToday.completed}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Pending</p>
                                                                <h4>{sheduledForToday.pending}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Cancelled</p>
                                                                <h4>{sheduledForToday.cancelled}</h4>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                        {(overAllSummary !== undefined && overAllSummary !== null) &&
                                            <div className='col-lg-6'>
                                                <div class="db_box">
                                                    <div className='db_header'>
                                                        <h5>Overall Summary</h5>
                                                        <div className='dbh_right'>
                                                            <select className='form-control input-sm'>
                                                                <option>This Month</option>
                                                                <option></option>
                                                                <option></option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='db_body'>
                                                        <div class="spd_card">
                                                            <div className='spd_card_list'>
                                                                <p> Bookings</p>
                                                                <h4>{overAllSummary.totalBookings}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Completed</p>
                                                                <h4>{overAllSummary.completedBookings}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Pending</p>
                                                                <h4>{overAllSummary.PendingBooings}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Income</p>
                                                                <h4>{overAllSummary.income}</h4>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {/* Pending for Acknowledgement */}
                                    <div class="row ">
                                        {(pendingForAcknowledge !== undefined && pendingForAcknowledge !== null && pendingForAcknowledge.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title">Pending for Acknowledgement</h4></div>
                                                {pendingForAcknowledge.map(item =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box">
                                                            <div className='db_header'>
                                                                <h5>{item.service_type === "Doctor" ? "Teleconsultation" : "Homecare"}</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{item.booking_id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            <li><Link to={`/bookings-summary/${item.id}`} state={{ type: item.type }}>Booking Info</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='info_text'>
                                                                        <h5>{item.name}</h5>
                                                                        <p>{item.gender}, {item.age} Yrs</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                <div className='date'> {(moment(item.appointment_date).utc()).format("MMM DD")}, {(moment(item.appointment_date).utc()).format("hh:mm a")}</div>
                                                                <div>
                                                                    <button className='btn btn-sm btn-success' onClick={e => AcceptRejectBooking(item.booking_id, true)}>Accept</button>
                                                                    <a style={{ cursor: "pointer" }} className='btnreject ml-1' onClick={e => AcceptRejectBooking(item.booking_id, false)}>Reject</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }

                                    </div>


                                    {/* Today's Appointments */}
                                    <div class="row ">
                                        {(todayAppoinment !== undefined && todayAppoinment !== null && todayAppoinment.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title" style={{fontWeight:"600"}}>Today's Appointments</h4></div>
                                                {todayAppoinment.map(item =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box" style={{border:"1px solid #056B66"}}>
                                                            <div className='db_header'  style={{backgroundColor:"#056B66"}}>
                                                                <h5>Teleconsultation</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{item.booking_id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            <li><Link to={`/bookings-summary/${item.booking_id}`} state={{ type: item.type }}>Booking Info</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='info_text'>
                                                                        <h5>{item.name}</h5>
                                                                        <p>{item.gender}, {item.age} Yrs</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                <div className='date'> {(moment(item.appointment_date).utc()).format("MMM DD")}, {(moment(item.appointment_date).utc()).format("hh:mm a")}</div>
                                                                <div>
                                                                    {(item.status.toLowerCase() !== "cancelled") ?
                                                                        ((item.is_confirmed === 1 && (moment(item.appointment_date).utc()).format("DD/MM/YYYY") === today) ?
                                                                            <Link to="/video-call" state={{ roomId: item.room_id }}> <i className='fa fa-video'></i>  Join Call</Link> :
                                                                            item.is_confirmed === 0 ? <a style={{color:"orange"}}>Pending</a> : <a style={{color:"green"}}>Confirmed </a>)
                                                                        : <button className='btn btn-sm btn-success' onClick={e => AcceptRejectBooking(item.booking_id, true)}>Accept</button>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }

                                    </div>

                                    {/* Recent Prescription */}
                                    <div class="row ">
                                        {(recentPrescription !== undefined && recentPrescription !== null && recentPrescription.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title">Recent Prescription</h4></div>
                                                {(recentPrescription != null && recentPrescription != undefined && recentPrescription.length > 0) && recentPrescription.map(item =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box">
                                                            <div className='db_header'>
                                                                <h5>E-Prescription </h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{item.id}</p>
                                                                    <span><a onClick={() => setPrescriptionid(item.id)} target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a></span>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='info_text'>
                                                                        <h5>{item.name}</h5>
                                                                        <p>{item.gender}, {item.age} Yrs</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }
                                        
                                    </div>

                                    {/* Upcoming Bookings (Next 7 Days) */}
                                    <div class="row ">
                                        {(upcomingBookings !== undefined && upcomingBookings !== null && upcomingBookings.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title">Upcoming Bookings (Next 7 Days)</h4></div>
                                                {upcomingBookings.map(item =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box">
                                                            <div className='db_header'>
                                                                <h5>Teleconsultation</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{item.id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            <li><Link to={`/bookings-summary/${item.id}`} state={{ type: item.type }}>Booking Info</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='info_text'>
                                                                        <h5>{item.name}</h5>
                                                                        <p>{item.gender}, {item.age} Yrs</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                <div className='date'> {(moment(item.appointment_date).utc()).format("MMM DD")}, {(moment(item.appointment_date).utc()).format("hh:mm a")}</div>
                                                                <div>
                                                                    {(item.status.toLowerCase() !== "cancelled") ?
                                                                        ((item.is_confirmed === 1 && (moment(item.appointment_date).utc()).format("DD/MM/YYYY") === today) ?
                                                                            <Link to="/video-call" state={{ roomId: item.room_id }}> <i className='fa fa-video'></i>  Join Call</Link> :
                                                                            item.is_confirmed === 0 ? <a style={{color:"orange"}}>Pending</a> : <a style={{color:"green"}}>Confirmed </a>)
                                                                        : <button className='btn btn-sm btn-success' onClick={e => AcceptRejectBooking(item.booking_id, true)}>Accept</button>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        }

                                    </div>



                                </div>
                            </div>
                        </div> :
                        <div class="defaultloader">
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#056B66"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            /></div>
                    }
                </div>
            </section>
            {prescriptionid !== undefined && prescriptionid !== null && prescriptionid !== "" ?
                                            <div id="capture">
                                                <Eprecriptiondownload prescriptionid={prescriptionid} setPrescriptionid={setPrescriptionid}></Eprecriptiondownload>
                                            </div>
                                            : ""
                                        }
        </div>

    </>);
}
export default ProviderDashboard;