import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { AccountBookingComponent } from '../../../components'
import { BookingServices } from '../../../services'
import axios from 'axios'
import moment from 'moment'
import { ThreeDots } from 'react-loader-spinner'

const token = localStorage.getItem("token")
const baseurl = process.env.REACT_APP_API_BASE_URL


const MyBookings = () => {

    const apiFunction = new BookingServices()
    const { state } = useLocation()
    const paymentStatus = state ? state.status
        ? state.status : null : null
    const [bookingDataMain, setBookingDataMain] = useState([])
    const [isloading, setIsLoading] = useState(true)
    const [status, setStatus] = useState("Upcoming")
    const [bookingType, setbookingType] = useState("Upcoming")
    const [carddata, setCardData] = useState([])
    const [sortby, setsortby] = useState("")
    const [filterset, setfilterset] = useState([])
    const location = useLocation()


    useEffect(() => {
        // setStatus(location.state?.status || status)
        // console.log(status, "status")
        setIsLoading(true);

        loadcarddata();

        console.log("call by filter",filterset)
    }, [status,sortby,filterset])

    // useEffect(() => {
    //     var temp = carddata;
    //     if (sortby !== null && sortby !== "") {
    //         if (sortby === "service_provider") {
    //             temp = temp.sort((a, b) =>
    //                 a.provider_name.localeCompare(b.provider_name));
    //         }
    //         if (sortby === "service") {
    //             temp = temp.sort((a, b) =>
    //                 a.service.localeCompare(b.service));
    //         }
    //         setCardData(temp)

    //     }
    //     else {
    //         setCardData(temp)
    //     }
    // }, [sortby])

    useEffect(() => {
        if (paymentStatus === "true") {
            return toast.success("Payment successfully received")
        } else if (paymentStatus === "false") {
            return toast.error("Payment not received error!!")
        }
    }, [paymentStatus])

    const loadcarddata = async () => {
        setIsLoading(true)
        var config = {

            headers: {
                'authorization': token
            }
        };
          console.log(status,"this is status  ")
        const result = await axios.get(`${baseurl}bookings/user?BookingType=${status}&status=&sort_by=${sortby}&serviceType=User`, config)
        console.log(result)
        if (result !== null && result !== undefined) {
            console.log(result.data, "data")
            setCardData(result.data.data)
           
            console.log("Amit by fileter : ",result.data.filterset)
            setIsLoading(false)

        }
        setIsLoading(false)
    }


    return (

        <AccountBookingComponent sortby={sortby} setsortby={setsortby} setCardData={setCardData} setStatus={setStatus} status={status} data={bookingDataMain} selfDetails={true} isloading={isloading} setfilterset={filterset} carddata={carddata} type="user" loadcarddata={loadcarddata} />
    )
}

export default MyBookings