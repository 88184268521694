import React, { useEffect } from 'react'
import { Footer, HomepageHeader } from '../../components'
import about_bnr from "../../images/about/about_bnr.png"
import care from "../../images/about/Care.png"
import trust from "../../images/about/trust.png"
import excellence from "../../images/about/excellence.png"
import ctm1 from "../../images/images/ctm1.png"
import ctm2 from "../../images/images/ctm2.png"
import ctm3 from "../../images/images/ctm3.png"
import linkedinOutlined from "../../images/images/linkedin-outlined.png"
import { useState } from 'react'


const About = () => {
   
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


  

    return (
        <>
            <HomepageHeader />
            {/*Inner Page Banner*/}
            <section className="banner_inner">
                <div className="container">
                    <div className="card_flex">
                        <div className="card_text">
                            <h1>About FastTrack</h1>
                            <h2>pmassa porttitor hac proin lacus interdum amet porttitor nibh est rhoncus nisl.</h2>
                        </div>
                        <div className="card_img">
                            <div className="dr_img"><img src={about_bnr} /></div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Mission & Vision */}
            <section className="mv_sec paddtop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <div className="ab_mvCard">
                                <h3 className="heading2 text-center">Our Mission</h3>
                                <div className="ab_whiteCard">
                                    <p className="para2 mt0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id neque, tristique pellentesque at imperdiet integer malesuada. Diam ut duis massa platea aliquet amet, non egestas.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="ab_mvCard">
                                <h3 className="heading2 text-center">Our Vision</h3>
                                <div className="ab_whiteCard">
                                    <p className="para2 mt0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id neque, tristique pellentesque at imperdiet integer malesuada. Diam ut duis massa platea aliquet amet, non egestas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div>
                {/* Our values */}
                <section className="values_sec paddtop ">
                    <div className="container">
                        <div className="row">
                            <h3 className="heading2 text-center paddbottom">Our Values</h3>
                            <div className="col-lg-4 col-sm-4">
                                <div className="values_categ ">
                                    <img src={trust} alt="trust" />
                                    <h4>Trust</h4>
                                    <p className="para2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id neque, tristique pellentesque at imperdiet integer malesuada. Diam ut duis massa platea aliquet amet, non egestas.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-4">
                                <div className="values_categ ">
                                    <img src={excellence} alt />
                                    <h4>Excellence</h4>
                                    <p className="para2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id neque, tristique pellentesque at imperdiet integer malesuada. Diam ut duis massa platea aliquet amet, non egestas.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-4">
                                <div className="values_categ ">
                                    <img src={care} alt />
                                    <h4>Care</h4>
                                    <p className="para2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id neque, tristique pellentesque at imperdiet integer malesuada. Diam ut duis massa platea aliquet amet, non egestas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/*Meet our mentors */}
                <section className="mentors_sec paddtop">
                    <div className="container">
                        <div className="row">
                            <h3 className="heading2 text-center paddbottom">Meet our mentors</h3>
                            <div className="mentor_box">
                                <div className="ab_cardflex">
                                    <div className="mtr_card ab_whiteCard text-center">
                                        <div className="mentor_img"> <img src={ctm1} alt /></div>
                                        <h4>Deepak  Agnihotri</h4>
                                        <p>(Founder &amp; CEO)</p>
                                    </div>
                                    <div className="ab_mentorinfo">
                                        <h4>Deepak Agnihotri, Founder and Chief Executive Officer,FastTrack</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id neque, tristique pellentesque at imperdiet integer malesuada. Diam ut duis massa platea aliquet amet, non egestas. Vel aliquam senectus ac, fermentum. Donec orci quis cras ornare adipiscing. Fermentum vitae senectus tellus nulla. Malesuada posuere tempus neque odio in lacus egestas diam. Dui in amet, consectetur proin. Adipiscing sem placerat tellus.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id neque, tristique pellentesque at imperdiet integer malesuada. Diam ut duis massa platea aliquet amet, non egestas. Vel aliquam senectus ac, fermentum. Donec orci quis cras ornare adipiscing. Fermentum vitae senectus tellus nulla. Malesuada posuere tempus neque odio in lacus egestas diam. Dui in amet, consectetur proin. Adipiscing sem placerat tellus eget cras sapien bibendum duis non.</p>
                                        <p><span className="mailto">Email : <a href="mailto:founders@FastTrack.com">founders@FastTrack.com</a>|</span><span className="connectin">Connect with Deepak : <a href="#"><img src={linkedinOutlined} alt /></a></span></p>
                                    </div>
                                </div>
                                <div className="ab_cardflex">
                                    <div className="mtr_card ab_whiteCard text-center">
                                        <div className="mentor_img"> <img src={ctm1} alt /></div>
                                        <h4>Deepak  Agnihotri</h4>
                                        <p>(Founder &amp; CEO)</p>
                                    </div>
                                    <div className="ab_mentorinfo">
                                        <h4>Deepak Agnihotri, Founder and Chief Executive Officer,FastTrack</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id neque, tristique pellentesque at imperdiet integer malesuada. Diam ut duis massa platea aliquet amet, non egestas. Vel aliquam senectus ac, fermentum. Donec orci quis cras ornare adipiscing. Fermentum vitae senectus tellus nulla. Malesuada posuere tempus neque odio in lacus egestas diam. Dui in amet, consectetur proin. Adipiscing sem placerat tellus.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id neque, tristique pellentesque at imperdiet integer malesuada. Diam ut duis massa platea aliquet amet, non egestas. Vel aliquam senectus ac, fermentum. Donec orci quis cras ornare adipiscing. Fermentum vitae senectus tellus nulla. Malesuada posuere tempus neque odio in lacus egestas diam. Dui in amet, consectetur proin. Adipiscing sem placerat tellus eget cras sapien bibendum duis non.</p>
                                        <p><span className="mailto">Email : <a href="mailto:founders@FastTrack.com">founders@FastTrack.com</a>|</span><span className="connectin">Connect with Deepak : <a href="#"><img src={linkedinOutlined} alt /></a></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/*Core team members */}
            <section className="ctm_sec paddtop paddbottom">
                <div className="container">
                    <div className="row">
                        <h3 className="heading2 text-center paddbottom">Core team members</h3>
                        <div className="ab_cardflex">
                            <div className="ab_carditem ab_whiteCard text-center">
                                <div className="ctm_img"> <img src={ctm1} alt /></div>
                                <h4>Brijesh Vaishnawa</h4>
                                <p>(Vice President - Human Resources )</p>
                            </div>
                            <div className="ab_carditem ab_whiteCard text-center">
                                <div className="ctm_img"> <img src={ctm2} alt /></div>
                                <h4>Amrita Vaishnawa</h4>
                                <p>(Vice President - Human Resources )</p>
                            </div>
                            <div className="ab_carditem ab_whiteCard text-center">
                                <div className="ctm_img"> <img src={ctm3} alt /></div>
                                <h4>Parul Agnihotri</h4>
                                <p>(Vice President - Human Resources )</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />




        </>
    )
}

export default About