import APIHandler from '../services/APICore';
import HomepageHeader from '../components/headers/Header';
import SidebarUserPanel from '../components/user/Sidebar';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Bookingsdetails from '../components/Bookingsdetails';
import BookingsActivities from '../components/BookingsActivities';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';

const Bookingssummary = ({ service }) => {
    let { state } = useLocation()
    const [status, setStatus] = useState("BookingSummary")
    const [apidata, setapidata] = useState([])
    const [eprescription, setepresciption] = useState([])
    const [medicalreport, setmedicalreport] = useState([])
    const [activelog, setactivelog] = useState([])
    const { id } = useParams();
    const [stype,setStype] = useState("")

    const getdata = async () => {
        const result = await APIHandler.get(`bookings/${id}`)

        setapidata(result.data)

    }
    const getPrecriptio = async () => {
        const result = await APIHandler.get(`bookings/${id}/prescriptions`)
        console.log(result.data)
        setepresciption(result.data)

    }
    const report = async () => {
        const result = await APIHandler.get(`bookings/${id}/reports`)
        console.log(result.data)
        setmedicalreport(result.data)

    }
    const log = async () => {
        const result = await APIHandler.get(`bookings/activities/${id}`)
        console.log(result.data)
        setactivelog(result.data)

    }
    useEffect(() => {
        getdata();
        getPrecriptio();
        report();
        log();
        setStype(state.type)
    }, [])



    return (
        <>
            <div className='outerhomepage'>
                {/*Top Navbar Menu*/}
                <HomepageHeader />
                <section className=" paddbottom">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <SidebarUserPanel />

                        <div className="right_panel ">
                            <div class="content-wrapper" style={{ marginLeft: 0 }}>

                                <div className='content content-body'>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="content-header">
                                                <h1 class="pull-left">
                                                    Bookings ID: {id}
                                                </h1>
                                                <Link to="/accounts-bookings"><button className='btn btnbtnjoin pull-right'><i className='fa fa-angle-double-left'></i> Back To Bookings</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='spacetop'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="tab">
                                                    <button className={status === "BookingSummary" ? 'active' : ""} aria-current="true" onClick={() => setStatus("BookingSummary")}>Booking Summary</button>
                                                    <button className={status === "Activities" ? 'active' : ""} onClick={() => setStatus("Activities")}>Activities</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {status === "BookingSummary" ?
                                        < Bookingsdetails apidata={apidata} />

                                        : <BookingsActivities eprescription={eprescription} medicalreport={medicalreport} activelog={activelog} stype={stype} getPrecriptio={getPrecriptio} report={report}/>




                                    }

                                </div>



                            </div>
                        </div>
                    </div>
                </section>





            </div>




        </>
    );

}
export default Bookingssummary