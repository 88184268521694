// Routes define here 
// descrption:- last updated 15-06-22
// descrption:- By Mayur

// Validating --- if email return true else false
export const ValidateEmail = (inputText) => {
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (inputText.match(mailformat)) {
        return true;
    }
    else
        return false;
}


// Validating --- if Mobile Number return true else false
export const validateIsNumber = (s) => {

    var rgx = /^[0-9]*\.?[0-9]*$/;

    return s.match(rgx);
}


export const regMobile = /^\d+$/;
export const regHtml = /<(.|\n)*?>/g;
export const regEmailValidation = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


