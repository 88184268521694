import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { HomepageHeader, SidebarUserPanel } from '../../components'
import { OnboardUser } from '../../services'

const ServicesProviderStarter = () => {
    const apifunction = new OnboardUser();
    const [teleconsultaion, setTeleconsultaion] = useState(0)
    const [homeCare, setHomeCare] = useState(0)
    const [ambulance, setAmbulance] = useState(0)
    let serviceList = JSON.parse(localStorage.getItem('services'))
    console.log(serviceList)

    useEffect(() => {
        if (serviceList !== null && serviceList !== []) {
            serviceList.map((val) => {
                if (val.service == 'Doctor') {
                    setTeleconsultaion(val)
                } else if (val.service == 'Nurse') {
                    setHomeCare(val)
                } else if (val.service == 'Ambulance') {
                    setAmbulance(val)
                }
            })
        }
    }, [])
    let token=localStorage.getItem('token')
    useEffect(()=>{
        apifunction.getService(token).then(result=>{
            if(result.success===true){
                localStorage.setItem("services", JSON.stringify(result.data))
            }
        })
    },[])
    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="container">
                    <div className="userpanel_page service-provider-page bg_light" style={{ height: "60rem" }}>

                        <div className='service-provider-table' style={{ margin: "115px auto" }}>
                            <h2 className='mb20'>Want to be a</h2>
                            <table>
                                <tbody>
                                    <tr>
                                        <th className='service-provider-th'>Service</th>
                                        <th className='service-provider-th'>Status</th>
                                        <th className='service-provider-th'>Action</th>
                                    </tr>
                                    <tr>
                                        <td>
                                        <h5>Teleconsultation</h5></td>
                                        
                                            <td>{
                                                teleconsultaion.is_approved==1?"Approved":teleconsultaion.is_approved==0?"Applied":"Not Applied"
                                            }</td>
                                        <td className='hover-service-td'>{
                                                teleconsultaion.is_approved==1?<Link to={"/doctor-profile"}>View Profile</Link>:teleconsultaion.is_approved==0?<Link to={"/doctor-profile"}>View Profile</Link>:<Link to={"/service-provider-teleconsultation"}>Apply Now</Link>
                                            }</td>
                                    </tr>
                                    <tr>

                                        <td><h5>Homecare</h5></td>
                                        <td>{
                                                homeCare.is_approved==1?"Approved":homeCare.is_approved==0?"Applied":"Not Applied"
                                            }</td>
                                        <td className='hover-service-td'>{
                                                homeCare.is_approved==1?<Link to={""}>View Profile</Link>:homeCare.is_approved==0?<Link to={""}>View Profile</Link>:<Link to={"/service-provider-homecare"}>Apply Now</Link>
                                            }</td>
                                    </tr>
                                    <tr>

                                        <td><h5>Ambulance</h5></td>
                                        
                                            <td>{
                                                ambulance.is_approved==1?"Approved":ambulance.is_approved==0?"Applied":"Not Applied"
                                            }</td>
                                        <td className='hover-service-td'>{
                                                ambulance.is_approved==1?<Link to={""}>View Profile</Link>:ambulance.is_approved==0?<Link to={""}>View Profile</Link>:<Link to={"/service-provider-teleconsultation"}>Apply Now</Link>
                                            }</td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* {
                                teleconsultaion === 0 && <Link to="/service-provider-teleconsultation" className='btn btn-theme ml-2'>Teleconsultancy</Link>
                            }
                            {
                                homeCare === 0 && <Link to="/service-provider-homecare" className='btn btn-theme ml-2'>Homecare</Link>
                            }
                            {
                                ambulance === 0 && <Link to="/service-provider-teleconsultation" className='btn btn-theme ml-2'>Ambulance</Link>
                            } */}
                        </div>
                        {/* Side bar */}
                        {/* <SidebarUserPanel /> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ServicesProviderStarter