import React, { useEffect, useState } from 'react'
import { FaqsSections, Footer, HomepageHeader } from '../../components'
import CheckImg from "../../images/images/check.png"
import SelectSpeciallityImg from "../../images/howItWorks/hiw1.png"
import AudioVideoImg from "../../images/howItWorks/hiw2.png"
import getDigitalPrescriptionImg from "../../images/howItWorks/hiw3.png"
import AmbulanceAls from "../../images/ambulance/abl1.png"
import AmbulanceBls from "../../images/ambulance/abl2.png"
import AmbulancePts from "../../images/ambulance/abl3.png"
import AmbulanceImg from "../../images/ambulance/ambulance.png";
import LocationIcon from '../../images/home/map-pin.png'
import SearchIcon from '../../images/home/search.png'
import LocationsList from '../../components/locations/LocationsList'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { err_10DigitMobile, err_NotName, HtmlErrorMessage } from '../../enums/Enums'

const regMobile = /^\d+$/;
const regHtml = /<(.|\n)*?>/g;

const Ambulance = () => {
    const [search_key, setsearch_key] = useState("");
    const [search_keyCity, setsearch_keyCity] = useState("");
    const [makeActive, setMakeActive] = useState("Advance life support")
    

    const navigate = useNavigate()
    const [searchAmbulanceForm, setsearchAmbulanceForm] = useState({
        name: "",
        pick_upAddress: "",
        mobile: ""
    })
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const { name, pick_upAddress, mobile } = searchAmbulanceForm;

    const onInputChange = e => {
        setsearchAmbulanceForm({
            ...searchAmbulanceForm, [e.target.name]:
                e.target.name === "mobile" ?
                    // Check if not number it will return nothing only for mobile
                    e.target.value.match(regMobile) ? e.target.value : ""
                    : e.target.value
        })
    }
    const searchAmbulance = (e) => {
        e.preventDefault();
        if (!name?.trim()) {
            return toast.error(err_NotName)
        }
        else if (regHtml.test(name?.trim())) {
            return toast.error(HtmlErrorMessage)
        }
        else if (!pick_upAddress?.trim()) {
            return toast.error("please enter pick up address")
        }
        else if (regHtml.test(pick_upAddress?.trim())) {
            return toast.error(HtmlErrorMessage)
        }
        else if (mobile.length !== 10) {
            return toast.error(err_10DigitMobile)
        } else {

            return navigate('/search',
                {
                    state: {
                        locationState: pick_upAddress,
                        searchKeyState: makeActive,  // Make active have value of ambulalance type
                        pageType: "ambulance"
                    }
                })
        }

        
    }
    return (
        <div className='outerhomepage'>
            <HomepageHeader />

            {/* --Inner Page Banner-- */}
            <section className="banner_inner">
                <div className="container">
                    <div className="ambulance_box">
                        {/* <div className="ambulance_form">
                            <h3 className="ambu_title">Choose Ambulance</h3>
                            <div className="abl_tab">
                                <div className={makeActive === "Advance life support" ? "abl_items active" : "abl_items"} onClick={() => setMakeActive('Advance life support')}><div className="abl_icon "><img src={AmbulanceAls} /></div> <div className="abl_name">Advance Life Support</div></div>
                                <div className={makeActive === "Basic Life Support" ? "abl_items active" : "abl_items"} onClick={() => setMakeActive('Basic Life Support')}><div className="abl_icon"><img src={AmbulanceBls} /></div> <div className="abl_name">Basic Life Support</div></div>
                                <div className={makeActive === "Patient Transport Service" ? "abl_items active" : "abl_items"} onClick={() => setMakeActive('Patient Transport Service')}><div className="abl_icon"><img src={AmbulancePts} /></div> <div className="abl_name">Patient Transport Service</div></div>
                            </div>
                            <form className="abl_form" onSubmit={(e) => searchAmbulance(e)}>
                                <p>Basic details</p>
                                <div className="form-group">
                                    <input type="text" name='name' value={name} onChange={(e) => onInputChange(e)} className="form-control" id="exampleInputEmail1" placeholder="Name" />
                                </div>
                                <div className="form-group">
                                    <input type="text" name='pick_upAddress' value={pick_upAddress} onChange={(e) => onInputChange(e)} className="form-control" id="exampleInputEmail1" placeholder="Pick up Address" />
                                </div>
                                <div className="form-group">
                                    <input type="text" name='mobile' maxLength={10} value={mobile} onChange={(e) => onInputChange(e)} className="form-control" id="exampleInputEmail1" placeholder="Mobile number" />
                                </div>
                                <div className>
                                    <button type='submit' className="btn btn_fill btn-block">Continue</button>
                                </div>
                            </form>
                        </div> */}
                    <div className='search_box_content'>
                    <div className='ambulance_content'>                       
                          <div className="ambulance_text">
                                    <h1>Advance Life Support</h1>
                                    <h2>Lorem ipsum dolor sit amet</h2>
                                </div>
                                </div>
  <div className="searchbox">
                    <div className="locality">
                      <span className="mapicon">
                        <img src={LocationIcon} />
                      </span>
                      <input
                        type="text"
                        value={search_keyCity}
                        onChange={(e) => setsearch_keyCity(e.target.value)}
                        className="form-control"
                        placeholder="Pickup Location"
                      />
                    </div>
                    <div className={"cardSuggetionsLocation"}>
                      {/* Render Location List */}
                      <LocationsList
                        search_keyCity={search_keyCity}
                        setsearch_keyCity={setsearch_keyCity}
                      />
                    </div>
                    <div className="vline" />
                    <div className="keyword">
                      <span className="searchicon">
                        <img src={SearchIcon} />
                      </span>
                      <form>
                        <input
                          type="text"
                          value={search_key}
                          onChange={(e) => setsearch_key(e.target.value)}
                          className="form-control"
                          placeholder="Drop Location"
                        />
                      </form>
                    </div>
                  </div>

                    </div>
                      
                       {makeActive === "Advance life support" &&
                            <div className="ambulance_content">
                                <div className="ambulance_img">
                                    <img src={AmbulanceImg} />
                                </div>
                                {/* <div className="ambulance_text">
                                    <h1>Advance life support</h1>
                                    <h2 className='text-center'>Lorem ipsum dolor sit amet</h2>
                                </div> */}
                            </div>
                        }
                    
                        {/* {makeActive === "Basic Life Support" &&
                            <div className="ambulance_content">
                                <div className="ambulance_img">
                                    <img src={AmbulanceImg} />
                                </div>
                                <div className="ambulance_text">
                                    <h1>Basic life support</h1>
                                    <h2>Lorem ipsum dolor sit amet</h2>
                                </div>
                            </div>
                        }
                        {makeActive === "Patient Transport Service" &&
                            <div className="ambulance_content">
                                <div className="ambulance_img">
                                    <img src={AmbulanceImg} />
                                </div>
                                <div className="ambulance_text">
                                    <h1>Patient Transport Service</h1>
                                    <h2>Lorem ipsum dolor sit amet</h2>
                                </div>
                            </div>
                        } */}

                    </div>
                </div>
            </section>


            {/* How it works*/}
            <section className="how_it_works ">
                <div className="container">
                    <div className="paddtop paddbottom">
                        <h3 className="heading2 text-center">How it works</h3>
                        <div className="hiw_section">
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={SelectSpeciallityImg} alt /></div>
                                <p className="description">Select your pickup &amp; drop</p>
                            </div>
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={AudioVideoImg} alt /></div>
                                <p className="description">Choose your services</p>
                            </div>
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={AudioVideoImg} alt /></div>
                                <p className="description">Select your nearset ambulance</p>
                            </div>
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={getDigitalPrescriptionImg} alt /></div>
                                <p className="description">Ambulance on it’s way</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Benefits */}
            <section className="benefit_sec paddtop paddbottom bg_light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-8">
                            <div className="benefit_content">
                                <h4 className="benefit_title">Benefits of Online Consultation</h4>
                                <p className="para2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna vulputate eu tellus est tincidunt. Ultrices condimentum in nunc, scelerisque turpis est. </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <div className="benefit_list">
                                <p><img src={CheckImg} alt /> Consult Top Doctors 24x7</p>
                                <p><img src={CheckImg} alt /> Convenient and Easy</p>
                                <p><img src={CheckImg} alt /> Similar Clinic Experience</p>
                                <p><img src={CheckImg} alt /> 100% Safe Consultations</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FaqsSections />
            <Footer />
        </div>
    )
}

export default Ambulance