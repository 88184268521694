import React, { useEffect, useState } from 'react'
import { FaqsSections, Footer, HomepageHeader } from '../../components'
import LocationIcon from "../../images/home/map-pin.png"
import SearchIcon from "../../images/home/search.png"
import HomecareBanner from "../../images/images/homecare_bnr.png"
import HomeCareServices from '../../services/HomeCareServices'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import CheckImg from "../../images/images/check.png"
import SelectSpeciallityImg from "../../images/howItWorks/hiw1.png"
import ImageNotAvailable from "../../images/images/image_not_available.png"
import AudioVideoImg from "../../images/howItWorks/hiw2.png"
import getDigitalPrescriptionImg from "../../images/howItWorks/hiw3.png"
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { ShowProfileType } from '../../enums/Enums'
import LocationsList from '../../components/locations/LocationsList'

const HomeCare = () => {
    const apifunction = new HomeCareServices();
    const [search_keyCity, setsearch_keyCity] = useState("");
    const navigate = useNavigate();
    const [recommendedHomeCare, setRecommendedHomeCare] = useState([])
    const [shortTermHomeCare, setShortTermHomeCare] = useState([])
    const [longTermHomeCare, setLongTermHomeCare] = useState([])


    const [items, setItems] = useState([])
    const [search_key, setsearch_key] = useState("")
    const [isloading, setisloading] = useState(false)

    console.log(shortTermHomeCare)
    console.log(longTermHomeCare)

    useEffect(() => {
        setisloading(true)
        const delayDebounceFn = setTimeout(() => {
            if (search_key) {
                loadSearchList(search_key)
                setisloading(false)
            } else {
                setItems([])
            }
        }, 1000);
        return () => clearTimeout(delayDebounceFn)
    }, [search_key])

    const loadSearchList = (search_key) => {
        apifunction.SearchHomecareNurseList(search_key,search_keyCity).then(res => {
            if (res !== undefined) {
                var result = res
                if (result.success === true) {
                    setItems(result.data)
                }
            }
        })
    }

    const options = {
        margin: 1,
        className: "owl-theme",
        responsiveClass: true,
        // nav: true,
        autoplay: false,
        // navText: ["Prev", "Next"],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 4,
            }
        },
    }

    const loadHomeCareList = () => {
        apifunction.HomecareList().then(res => {
            if (res !== undefined) {
                var result = res
                if (result.success === true) {
                    result.data.forEach(element => {
                        return element.banner_image = element.banner_image.substr(0, 40) === "http://v2.fasttrackhealthcare.in/backend"
                            ? element.banner_image :
                            "http://v2.fasttrackhealthcare.in/backend/" + element.banner_image
                    });
                    setRecommendedHomeCare(result.data)
                    setShortTermHomeCare(result.data)
                    setLongTermHomeCare(result.data)
                }
            }
        })
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        loadHomeCareList();
    }, [])

    const Homecarecard = ({ title, paragraph, image }) => {
        return (
            <div className="post-slide">
                <div className="white_card card_bg_bnr">
                    <div className="card_img ">
                        <img src={image ? image : ImageNotAvailable} alt className="cb_img" />
                    </div>
                    <div className="card_dtl text-center">
                        <h3 className="card_title">{title}</h3>
                        <p>{paragraph}</p>
                        <div className="card_btn">
                            <button type='button' onClick={() => onClickConsultNow(title)} className="btn btn_outline btn-xs">View </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const onEnterPress = (e) => {
        e.preventDefault();
        return navigate('/search', { state: { locationState: localStorage.getItem("SelectedLocation"), searchKeyState: search_key, pageType: "homecare" } })
    }
    const onClickConsultNow = (search_key) => {
        return navigate('/search', { state: { locationState: localStorage.getItem("SelectedLocation"), searchKeyState: search_key, pageType: "homecare" } })
    }

    return (
        <div className='outerhomepage'>
            <HomepageHeader />

            {/*Inner Page Banner*/}
            <section className="banner_inner">
                <div className="container">
                    <div className="card_flex">
                        <div className="card_text">
                            <h1>Expert <span className='bold-mail-heading'>H</span>ome <span className='bold-mail-heading'>C</span>are <span className='bold-mail-heading'>S</span>ervices</h1>
                            <h2>Book Verified Home Care Expert</h2>
                            {/* <div className="searchbox">
                                <div className="locality">
                                    <span className="mapicon"><img src={LocationIcon} /></span>
                                    <select className='form-control'>
                                        <option value="">Choose service</option>
                                        <option value="Short term nursing care">Short term nursing care</option>
                                        <option value="Long term nursing care">Long term nursing care</option>
                                        <option value="Physiotherapist">Physiotherapist</option>
                                      
                                    </select>
                                    {/* <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Choose service " /> */}
                                {/* </div> */}
                                {/* <div className="vline" />
                                <div className="keyword">
                                    <span className="searchicon"><img src={SearchIcon} /></span>
                                    <form onSubmit={(e) => onEnterPress(e)}>
                                        <input value={search_key} onChange={(e) => setsearch_key(e.target.value)} type="text" className="form-control" placeholder="Pick on expertise" />
                                    </form>
                                </div>
                                <div id={items.length} className="cardSuggetionsHomecare">
                                    {items.length > 0 ?
                                        <>
                                            <ul>
                                                {
                                                    items.map(item => (
                                                        <li key={item.id + "resultList"}>
                                                            <Link to="/show-profile-and-slots" state={{ userid: item.id, searchType: ShowProfileType.type2 }} >{item.name} - {item.type} </Link>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </>
                                        :
                                        <>
                                            {isloading ?
                                                <p className={search_key === "" ? "noRecordsPara displayNoneCls" : "noRecordsPara"}>
                                                    Loading...
                                                </p>
                                                :
                                                <p className={search_key === "" ? "noRecordsPara displayNoneCls" : "noRecordsPara"}>
                                                    No Records Found
                                                </p>
                                            }
                                        </>

                                    }

                                </div>
                            </div>  */}

<div className="searchbox">
                    <div className="locality">
                      <span className="mapicon">
                        <img src={LocationIcon} />
                      </span>
                      <input
                        type="text"
                        value={search_keyCity}
                        onChange={(e) => setsearch_keyCity(e.target.value)}
                        className="form-control"
                        placeholder="Location"
                      />
                    </div>
                    <div className={"cardSuggetionsLocation"}>
                      {/* Render Location List */}
                      <LocationsList
                        search_keyCity={search_keyCity}
                        setsearch_keyCity={setsearch_keyCity}
                      />
                    </div>
                    <div className="vline" />
                    <div className="keyword">
                      <span className="searchicon">
                        <img src={SearchIcon} />
                      </span>
                      <form>
                        <input
                          type="text"
                          value={search_key}
                          onChange={(e) => setsearch_key(e.target.value)}
                          className="form-control"
                          placeholder="Enter Name / Service"
                        />
                      </form>
                    </div>
                  </div>
                        </div>
                        <div className="card_img">
                            <div className="dr_img-homecare"><img src={HomecareBanner} /></div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Specialities Slider*/}
            <section className="specialities_Slider paddtop">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="slider_heading">
                                <h3 className="left-h">Recommended for you</h3>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <OwlCarousel key="recommendedHomeCareCard" {...options}>
                                {recommendedHomeCare.length > 0 ?
                                    recommendedHomeCare.map(item => (
                                        <Homecarecard key={item.id + "recommendedHomeCareCard"} title={item.name} paragraph={item.short_description} image={item.banner_image} />
                                    ))
                                    :
                                    "No records found"
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>

            {/* Short term nursing care*/}
            <section className="specialities_Slider paddtop">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="slider_heading">
                                <h3 className="left-h"><b>S</b>hort <b>T</b>erm <b>N</b>ursing <b>C</b>are</h3>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <OwlCarousel {...options}>
                                {shortTermHomeCare.length > 0 ?
                                    shortTermHomeCare.map(item => (
                                        <Homecarecard key={item.id + "recommendedHomeCareCard"} title={item.name} paragraph={item.short_description} image={item.banner_image} />
                                    ))
                                    :
                                    "No records found"
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>



            {/* Long term nursing care*/}
            <section className="paddtop">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="slider_heading">
                                <h3 className="left-h"><b>L</b>ong <b>T</b>erm <b>N</b>ursing <b>C</b>are</h3>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <OwlCarousel {...options}>
                                {longTermHomeCare.length > 0 ?
                                    longTermHomeCare.map(item => (
                                        <Homecarecard key={item.id + "recommendedHomeCareCard"} title={item.name} paragraph={item.short_description} image={item.banner_image} />
                                    ))
                                    :
                                    "No records found"
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>

            {/* How it works*/}
            <section className="how_it_works bg_light margintop">
                <div className="container">
                    <div className="paddtop paddbottom">
                        <h3 className="heading2 text-center">How it works</h3>
                        <div className="hiw_section">
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={SelectSpeciallityImg} alt /></div>
                                <p className="description">Select home care specialist</p>
                            </div>
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={AudioVideoImg} alt /></div>
                                <p className="description">Assessment</p>
                            </div>
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={AudioVideoImg} alt /></div>
                                <p className="description">Services initiated</p>
                            </div>
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={getDigitalPrescriptionImg} alt /></div>
                                <p className="description">continuous patient monitoring</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Benefits */}
            <section className="benefit_sec paddtop paddbottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-8">
                            <div className="benefit_content">
                                <h4 className="benefit_title">Benefits of Online Consultation</h4>
                                <p className="para2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna vulputate eu tellus est tincidunt. Ultrices condimentum in nunc, scelerisque turpis est. </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <div className="benefit_list">
                                <p><img src={CheckImg} alt /> Consult Top Doctors 24x7</p>
                                <p><img src={CheckImg} alt /> Convenient and Easy</p>
                                <p><img src={CheckImg} alt /> Similar Clinic Experience</p>
                                <p><img src={CheckImg} alt /> 100% Safe Consultations</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FaqsSections />
            <Footer />
        </div>
    )
}

export default HomeCare