import Table from 'react-bootstrap/Table';
import moment from 'moment/moment';
import { saveAs } from "file-saver";
import { BookingServices } from '../services';
import React, { Component, PropTypes, useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import { useParams } from 'react-router-dom';
import Eprecriptiondownload from '../pages/E-prescription/downloadeprescription';
import { toast } from 'react-toastify';
import axios from 'axios';
const token = localStorage.getItem("token")
const baseurl = process.env.REACT_APP_API_BASE_URL
import CircularProgress from '@mui/material/CircularProgress';

const BookingsActivities = ({ eprescription, medicalreport, activelog, stype, getPrecriptio, report }) => {
  const apiFunction = new BookingServices()
  const [prescription, setPrescription] = useState("")
  const [value, setValue] = useState(RichTextEditor.createValueFromString(prescription, 'markdown'));
  const { id } = useParams();
  const [UserType, setUserType] = useState("")
  const [precriptionData, setPriscriptionData] = useState(null)
  const [prescriptionid,setPrescriptionid]=useState("");
  const [isLoaded, setIsLoaded] = useState(true)
  const [filepath, setfilepath] = useState("")
  const [title, settitle] = useState("")
  const [show, setShow] = useState(false);



  const handleClose = () => {
    window.$("#modalmedicalreport").modal("hide")
  }
  const handleShow = () => {
    window.$("#modalmedicalreport").modal("show")
  }

  useEffect(() => {
    setUserType(localStorage.getItem("user_type"))
    console.log("user type change ", localStorage.getItem("user_type"))
    setPriscriptionData(eprescription)
  }, [])

  
  const saveFile = (link) => {
    saveAs(
      link,
      "file"
    );
  };

  const AddPrescription = (e) => {
    var data = {
      "prescription": prescription,
      "user_id": localStorage.getItem("userid"),
      "booking_id": id
    }

    apiFunction.AddPrescriptionAPI(data).then(result => {
      if (result !== undefined) {
        if (result.success === true) {
          console.log(result.data, "----prescription---")
          getPrecriptio()
          window.$("#modalepriscription").modal("hide")
          setPrescription("")
          setValue(RichTextEditor.createValueFromString("", 'markdown'))

        }
      }
    })
  }

  const handleChange = (v) => {
    setValue(v)
    setPrescription(v.toString("markdown"))
    console.log(v)

  }
  const postmedical = async (e) => {

    try {
      setIsLoaded(false)
      var data = {
        "report_name": title,
        "attachment": filepath,
        "booking_id": id

      };

      var config = {

        headers: {
          'authorization': token,
          'Content-Type': 'application/json'
        },

      };
      const result = await axios.post(`${baseurl}users/medicalreports`, JSON.stringify(data), config)
      console.log(result, "res-----------------------")
      if (result!==undefined) {
        setIsLoaded(true)
        toast.success("Medical Report is Uploaded")
        settitle("")
        setfilepath("")
        window.$("#modalmedicalreport").modal("hide")
        report()
      }
    } catch (error) {
      toast.error(error)
    }

  }

  const postmedicalreport = async (e) => {
    setIsLoaded(false)

    var config = {
      headers: {
        'authorization': token,
      },
    };
    const selectedFile = e.target.files[0];
    // const selectedFileName = e.target.files[0];
    const formData = new FormData();
    formData.append("files", selectedFile);

    const result = await axios.post(`${baseurl}uploads?for=medical_report`, formData, config)
    console.log(result.data.data, "result")
    if (result != null) {
      setfilepath(result.data.data.filePath)
      setIsLoaded(true)
    }

  }

  return (

    <>
      <div className='profile_body'>


        <div className="row ">
          <div className="col-lg-12"><h4 class="page_title">E-Prescriptions {stype === "service" ? (<a onClick={() => { window.$("#modalepriscription").modal("show") }} className='ml-1' title='Add Prescriptions'> <i className='fa fa-plus-circle'></i></a>) : ""} </h4>  </div>
          <div className="col-lg-12">
            <div className="dr_table">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Uploaded On</th>
                    <th>View</th>
                  
                  </tr>
                </thead>
                <tbody>
                { console.log("Rakesh : ",eprescription)}
                  {(eprescription != null && eprescription != undefined && eprescription.length > 0) ?
                   
                    (eprescription != null && eprescription.map((key) => (
                      <tr>
                        <td>E Prescription </td>
                        <td>{(moment(key.created_at).utc()).format("DD/MM/YYYY") + ", " + (moment(key.created_at).utc()).format("hh:mm a")}</td>
                        <td><a onClick={() => setPrescriptionid(key.id)} > Download</a></td>
                        {/* <td ><a onClick={() => saveFile(key.id)} > Click to download</a> </td> */}
                      </tr>


                    ))) : "No Record Found"}


                </tbody>
              </Table>
            </div>

          </div>

        </div>


        <div className="row mt-3">
          <div className="col-lg-12"><h4 class="page_title">Reports {stype === "user" && <a onClick={handleShow} className='add_mr ml-1' style={{ cursor: 'pointer' }} title='Add Reports'> <i className='fa fa-plus-circle'></i></a>}</h4>  </div>
          <div className="col-lg-12">
            <div className="dr_table">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Uploaded On</th>
                    <th>Download</th>
                   
                  </tr>
                </thead>
                <tbody>
                  {(medicalreport != null && medicalreport != undefined && medicalreport.length > 0) ?
                    (medicalreport != null && medicalreport.map((key) => (
                      <tr>
                        <td>{key.report_name}</td>
                        <td>{(moment(key.created_at).utc()).format("DD/MM/YYYY") + "," + (moment(key.created_at).utc()).format("hh:mm a")}</td>
                        <td><a href={key.attachment}>download</a></td>
                        {/* <td><a onClick={() => saveFile(key.attachment)}> Click to download</a></td> */}
                      </tr>

                    ))) : "No Record Found"}

                </tbody>
              </Table>


            </div>
          </div>
        </div>


        <div className="row mt-3">
          <div className="col-lg-12"><h4 class="page_title">Activity Logs</h4>  </div>
          <div className="col-lg-12">
            <div className="dr_table">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Performed By</th>
                    <th>Log Date Time</th>

                  </tr>
                </thead>
                <tbody>
                  {
                    (activelog != null && activelog != undefined && activelog.length > 0) ?


                      (activelog != null && activelog.map((key) => (
                        <tr>
                          <td>1</td>
                          <td>Mark</td>
                          <td>Otto</td>

                        </tr>

                      ))) : "No Record Found"}

                </tbody>
              </Table>


            </div>
          </div>
        </div>

        <div>
        {prescriptionid!==undefined && prescriptionid!==null && prescriptionid!==""?
        <div id="capture">
            <Eprecriptiondownload prescriptionid={prescriptionid} setPrescriptionid={setPrescriptionid}></Eprecriptiondownload>
          </div>
          :""
        }
          
        </div>




      </div>
      <div class="modal fade" id="modalepriscription" tabIndex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" style={{ width: "800px" }}>
            {/* <!-- Modal header --> */}
            <div class="modal-header">
              <h5 class="modal-title" id="modalforprofile">
                E-Prescription
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-24px" }}
                onClick={() => { window.$("#modalepriscription").modal("hide") }}
              >&times;</button>

            </div>
            <div class="modal-body">
              {/* <!-- Form START --> */}
              <RichTextEditor
                value={value}
                onChange={handleChange}
              />


            </div>


            <div class="modal-footer d-flex justify-content-between">
              <p></p>
              <button type="button" class="btn btn-sm btn-primary-soft"
                onClick={AddPrescription}
              >
                Save
              </button>



            </div>
          </div>

          {/* <!-- Form END --> */}
        </div>
        {/* <!-- Modal footer --> */}
      </div>

      <div
          class={"modal fade"}
          id="modalmedicalreport"
          tabIndex="-1"
          aria-hidden="false"
        >
          <div class="modal-dialog mydailog">
            <div class="modal-content">
              {/* <!-- Modal header --> */}
              <div class="modal-header">
                <h5 class="modal-title pull-left" id="modalpris">  Add Medical Report </h5>
                <button type="button" onClick={(e) => handleClose(e)} class="btn-close pull-right" data-bs-dismiss="Close" aria-label="Close">&times;</button>
              </div>
              <div class="modal-body">
                <div className='row'>
                  <div class="col-lg-12">
                    <div class="fd_input mb-2">
                      <div class="form-group"><label>Title</label>
                        <input type="text" name="name" class="form-control" placeholder="" value={title} onChange={(e) => settitle(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="fd_input mb-2">
                      <div class="form-group"><label>File/Attachment</label>


                        <input type={'file'} name="name" class="form-control" placeholder="" onChange={(e) => postmedicalreport(e)} />
                      </div>
                    </div>
                  </div>

                </div>


              </div>
              <div class="modal-footer d-flex justify-content-between">
                <button className='btn btn-info' onClick={(e) => postmedical(e)} disabled={!isLoaded}> {!isLoaded&&<CircularProgress color="inherit" size={16}/>} Upload</button>
              </div>
            </div>

            {/* <!-- Form END --> */}
          </div>
          {/* <!-- Modal footer --> */}
        </div>
    </>


  );

}
export default BookingsActivities