import * as React from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useLocation } from 'react-router';


// function randomID(len) {
//   let result = '';
//   if (result) return result;
//   var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
//   maxPos = chars.length,
//   i;
//   len = len || 5;
//   for (i = 0; i < len; i++) {
//     result += chars.charAt(Math.floor(Math.random() * maxPos));
//   }
//   return result;
// }
// console.log(randomID(5))URLSearchParams

export function getUrlParams(
  url: String = window.location.href): URLSearchParams {
    let urlStr = url.split('?')[1];
    return new URLSearchParams(urlStr);
  }
  
  export default function VideoCall() {
    const username=localStorage.getItem("user_name")
    const userid=localStorage.getItem("userid")
    let {state}=useLocation()
    console.log(state)
    const roomID = (state ===null|| state===undefined || state.roomId===undefined || state.roomId===null)?'123456':state.roomId;//getUrlParams().get('roomID') || randomID(5);
    let name=localStorage.getItem('user_name')
    let myMeeting = async (element: HTMLDivElement) => {
      // generate Kit Token
      const appID = 790128439;
      const serverSecret = "8462e8eda5ec8588427d88f01667cdca";
      const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userid,  username);


     // Create instance object from Kit Token.
      const zp = ZegoUIKitPrebuilt.create(kitToken);
      // start the call
      zp.joinRoom({
        container: element,
        sharedLinks: [
          {
            name: localStorage.getItem('user_name'),
            url:
              window.location.origin +
              window.location.pathname +
              '?roomID=' +
              roomID,
          },
        ],
        scenario: {
          mode: ZegoUIKitPrebuilt.GroupCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
        },
      });


  };

  return (
    <div
      className="myCallContainer"
      ref={myMeeting}
      style={{ width: '100vw', height: '100vh' }}
    ></div>
  );
}