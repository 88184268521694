import React, { useEffect, useState } from 'react'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import VinitaIcon from "../../images/images/vinita.png"
import userIcon from "../../images/adminpanel/UserIcon.svg"

const Sidebar = () => {
    const location = useLocation()
    const pathName = location.pathname
    const service = JSON.parse(localStorage.getItem("services"))
    const mymobilenumber = localStorage.getItem("user_mobile")
    const [cssSidebar, setCssSidebar] = useState("subMenusOpen")
    const [toggleButtonValue, setToggleButtonValue] = useState(false)
    const [isloading,setisloading] = useState(false)
    // const service=0
    //const service = JSON.parse(localStorage.getItem("services"))!==null?JSON.parse(localStorage?.getItem("services")):0
    const [activePanel, setActivePanel] = useState(

        (
        service!=null&&service!=undefined&& service.length>0)
        ?"ServiceProvider":"User"
        )
    let profilePic = localStorage.getItem('profile_pic')
    console.log(isloading,"loader")
  
    // const isDoctor = service.find(s => s.service === "doctor");
    // const isHomeCare = service.find(s => s.service === "nurse");
    // const isAmbulance = service.find(s => s.service === "ambulance");

    useEffect(() => {
        setisloading(true)
        console.log(service,"print services")
        let activemenu=localStorage.getItem("activemenu")
        if(activemenu!=null){
            setActivePanel(activemenu);
            setisloading(false)
            
        }
        else if (service != null && service.length > 0){

            setActivePanel("ServiceProvider")
            localStorage.setItem("activemenu","ServiceProvider")
            setisloading(false)
            
        }
    }, [service])
    const changeCss = () => {
        cssSidebar === "subMenusOpen" ? setCssSidebar("subMenusClose") : setCssSidebar("subMenusOpen");
    }
   
       




   
    // useEffect(() => {
    //     console.log(service,"sidebar service")
    //     if(service===null){

    //     }
    //     else if (pathName === "/accounts-bookings" || pathName === "/history-my-completed-booking" || pathName === "/history-my-doctors" || pathName === "/history-my-nurses" || pathName === "/history-my-payments" || pathName === "/history-my-medical-reports" || pathName === "/history-my-feedback-query" || pathName === "/my-dashboard" || pathName === "/doctor-profile" || pathName==="/bookings-summary") {
    //         setToggleButtonValue(false)
    //         setActivePanel("User")
    //     }
    //     else if(pathName === "/dashboard" || pathName === "/accounts-teleconsultaion" || pathName === "/my-patient" || pathName === "/want-to-be-service-provider" || pathName === "/my-patient-profile"){
    //         setToggleButtonValue(true)
    //         setActivePanel("Aggregator")
    //     }
    // }, [])
    // const handelToggleBtn = (e) => {
    //     if (e.target.checked){
    //         setActivePanel("Aggregator");
    //         setToggleButtonValue(true)
    //         document.getElementById('dashboard').click()
    // if(service==0 || service==[]){
    // document.getElementById('AggregatorPanel').click()
    // }else{
    //     document.getElementById('dashboard').click()
    // }
    //     }
    //     else{
    //         setActivePanel("User")
    //         setToggleButtonValue(false)
    //         document.getElementById('UserPanel').click()
    //     }
    // }
    const onchangetype = () => {
        if (activePanel === "User") {
            setActivePanel("ServiceProvider")
            localStorage.setItem("activemenu","ServiceProvider")
            localStorage.setItem("user_type", "ServiceProvider")
            isloading(false)
        }
        else if (activePanel === "ServiceProvider") {
            setActivePanel("User")
            localStorage.setItem("activemenu","User")
            localStorage.setItem("user_type", "User")
            isloading(false)
             
        }
    }
    useEffect(()=>{},[])
    console.log(service, "serv")
    console.log(activePanel, "activ")
    return (
        <>
            <div className="left_panel sidebar-postion-fixed">
                <div className="sidebar">
                    <ul className="sidebar_menu">
                        <li className="myuser">
                            <Link to="/doctor-profile">
                                <div className="userdtl">
                                    <div className="userpic">
                                        {console.log(profilePic, "pic")}
                                        <img src={(profilePic !== null && profilePic !== "null") ? profilePic : userIcon} alt />
                                    </div>
                                    <div className="userinfo">
                                        <h4>{localStorage.getItem("user_name")}</h4>

                                        View Profile

                                       
                                    </div>

                                </div>
                            </Link>
                        </li>


                  
                        {
                            
                        (service != undefined && service != null && service.length > 0 && activePanel=="ServiceProvider") ?
                            <>
                                <li className='header'>Service Provider Menu</li>
                                <li className={pathName === "/providerdashboard" ? "active" : ""}>
                                    <Link to="/providerdashboard">
                                        Dashboard
                                    </Link>
                                </li>

                                <li className={pathName === "/serviceprovider-bookings" ? "active" : ""}>
                                    <Link to="/serviceprovider-bookings">
                                        Bookings
                                    </Link>
                                </li>
                                <li className={pathName === "/my-patient" ? "active" : ""}>
                                    <Link to="/my-patient" className={pathName === "/my-patient" ? "active" : ""}>
                                        My Patient
                                    </Link>
                                </li>
                                <li className={pathName === "/Spincome" ? "active" : ""}>
                                    <Link to="/Spincome">
                                        <span>Income</span>
                                    </Link>
                                </li>                              

                                <li className={pathName === "/history-my-medical-reports" ? "active" : ""}>
                                    <Link to="/history-my-medical-reports">
                                        <span>Reports</span>
                                    </Link>
                                </li>





                            </> :
                            <>
                                <li className='header'>User Menu</li>
                                <li className={pathName === "/my-dashboard" ? "active" : ""}>
                                    <Link to="/my-dashboard">
                                        Dashboard
                                    </Link>
                                </li>

                                <li className={pathName === "/accounts-bookings" ? "active" : ""}>
                                    <Link to="/accounts-bookings">
                                        My Bookings
                                    </Link>
                                </li>

                                <li className={pathName === "/ServiceProvider" ? "active" : ""}>
                                    <Link to="/ServiceProvider">
                                        <span>Favorite</span>
                                    </Link>
                                </li>
                                <li className={pathName === "/E-precription" ? "active" : ""}>
                                    <Link to="/E-precription">
                                        <span>E-Prescriptions</span>
                                    </Link>
                                </li>
                                <li className={pathName === "/history-my-medical-reports" ? "active" : ""}>
                                    <Link to="/history-my-medical-reports">
                                        <span>Medical Reports</span>
                                    </Link>
                                </li>
                                <li className={pathName === "/history-my-payments" ? "active" : ""}>
                                    <Link to="/history-my-payments">
                                        <span>Transactions</span>
                                    </Link>
                                </li>
                                <li className={pathName === "/history-my-feedback-query" ? "active" : ""}>
                                    <Link to="/history-my-feedback-query">
                                        <span>Feedback & Query</span>
                                    </Link>
                                </li>

                            </>
                            
 
                        }                


                    </ul>
                </div>

                {(service != undefined && service != null && service.length > 0) && <button className='btn  button_set ' style={{backgroundColor: "#D8ECE8"}} onClick={onchangetype}>{(activePanel === "User") ? "Switch to Service Provider Menu" : "Switch to User Menu"}</button>}

            </div>
        </>
    )
}

export default Sidebar;