import { toast } from 'react-toastify';
import axios from 'axios';
// const ApibaseURL='http://fasttrack.acolabz.com/backend/api/v1'
let ApibaseURL= process.env.REACT_APP_API_BASE_URL


// Image URL API
export const DoctorUploadImageFor = async (formdata, type) => {
    try {
      const response = await fetch(`${ApibaseURL}/uploads?for=${type}`, {
        method: "post",
        headers: {
          'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem("token")
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(formdata),
      });
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
        
      }
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
        toast.error("Something went wrong , Please try again later")
    }
  };

  // Specialization Api
  export const Specialization = async (token) => {

    try {
      const response = await fetch(`${ApibaseURL}specialization`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(),
      });
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
        
      }
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
        toast.error("Something went wrong , Please try again later")
    }
  };


  // ------------------
  export const DoctorRegistered = async (reqdata,token) => {
    console.log(token,reqdata,"token")
    try {
      const config={
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
        }, 

      }
      const response = await axios.put(`${ApibaseURL}doctors/update`, JSON.stringify(reqdata),config);
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
      }
      console.log(response)
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
        toast.error(error)
    }
  };
