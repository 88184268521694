import { useEffect, useState } from 'react';
import HomepageHeader from '../../components/headers/Header'
import SidebarUserPanel from '../../components/user/Sidebar'
import APICore from '../../services/APICore';
import userIcon from "../../images/adminpanel/UserIcon.svg";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ThreeDots } from 'react-loader-spinner'
import { Link } from 'react-router-dom';



const ServiceProvider = () => {
    const [data, setdata] = useState([])
    const [isLoaded, setIsLoaded] = useState(true)
    const cardata = async () => {
        const result = await APICore.get(`/users/Doctor/list`)
        if (result != null) {
            if (result != undefined) {
                setdata(result.data)

            }
        }
        console.log(result, "user service")

    }

    const handleViewDoctorProfile = (id) => {
        console.log(id)
        let userId = localStorage.getItem('userid')
        if (id !== null) {
            try {
                OnePatientDetails(userId, id)
                    .then((res) => {
                        console.log(res)
                        if (res.success === true) {
                            navigateTo('/my-patient-profile', { state: { profileData: res.data} })
                        }
                    })
                    .catch();
            } catch (error) { }
        }
    }

    useEffect(() => { cardata(); }, [])
    return (
        <>
            <div className="outerhomepage">
                {/*Top Navbar Menu*/}
                <HomepageHeader />

                <section className=" paddbottom">
                    <div className="">
                        <div className="userpanel_page bg_light">

                            {/* Side bar */}
                            <SidebarUserPanel />

                            <div className="right_panel ">
                                <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                    {isLoaded ?
                                    <div class="content content-body">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="content-header">
                                                    <h1 class="pull-left">
                                                        Service Provider
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <input type="text" className='form-control' placeholder='Search' />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <select className='form-control'>
                                                            <option>Sort by Date</option>
                                                            <option>Sort by Booking ID</option>
                                                            <option>Sort by Doctor</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row spacetop">
                                            {/* My Doctors */}
                                            <div className='col-lg-12 '><h4 class="page_title">My Doctors</h4>   </div>
                                            {(data != null && data != undefined&& data.length>0)?
                                            (data != null && data != undefined) && data.map((key) => (
                                            <div className='col-lg-3'>
                                                <div class="db_box">
                                                    <div className='db_body'>
                                                        <div class="sv_center">
                                                            <div className='sv_photo'><img src={(key.profile_pic !== null && key.profile_pic !== "null") ? key.profile_pic : userIcon} alt /></div>
                                                            <div className='info_text'>
                                                                <h5>{key.name}</h5>
                                                                <p>{key.specialization}, {key.city}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='db_footer'>
                                                    <div className='linkbtn'><a onClick={() => handleViewDoctorProfile(key.id)}>Profile </a></div>
                                                    <div className='linkbtn'><Link to="/show-profile-and-slots"
                                                            state={{
                                                                userid: key.doctor_user_id,
                                                                searchType:"Doctor"}}>Book Again </Link></div>
                                                    </div>
                                                </div>
                                            </div>)):<p className='marginme'>"No Record Found"</p>}
                                            
                                            
                                             {/* My Nurses */}
                                             <div className='col-lg-12 '><h4 class="page_title">My Nurses</h4>  
                                             <p className='marginme'>"No Record Found"</p>
                                              </div>
                                            {/* <div className='col-lg-3'>
                                                <div class="db_box">
                                                    <div className='db_body'>
                                                        <div class="sv_center">
                                                            <div className='sv_photo'><img src="images/user.jpg" /></div>
                                                            <div className='info_text'>
                                                                <h5>Dr. Ashish Sabharwal</h5>
                                                                <p>Orthopedics, Bhopal</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='db_footer'>
                                                        <div className='linkbtn'><a href='#'>Profile </a></div>
                                                        <div className='linkbtn'><a href='#'>Bookings </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div class="db_box">
                                                    <div className='db_body'>
                                                        <div class="sv_center">
                                                            <div className='sv_photo'><img src="images/user.jpg" /></div>
                                                            <div className='info_text'>
                                                                <h5>Dr. Ashish Sabharwal</h5>
                                                                <p>Orthopedics, Bhopal</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='db_footer'>
                                                        <div className='linkbtn'><a href='#'>Profile </a></div>
                                                        <div className='linkbtn'><a href='#'>Bookings </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div class="db_box">
                                                    <div className='db_body'>
                                                        <div class="sv_center">
                                                            <div className='sv_photo'><img src="images/user.jpg" /></div>
                                                            <div className='info_text'>
                                                                <h5>Dr. Ashish Sabharwal</h5>
                                                                <p>Orthopedics, Bhopal</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='db_footer'>
                                                        <div className='linkbtn'><a href='#'>Profile </a></div>
                                                        <div className='linkbtn'><a href='#'>Bookings </a></div>
                                                    </div>
                                                </div>
                                            </div> */}


                                             {/*My Ambulance Providers */}
                                             <div className='col-lg-12 '><h4 class="page_title">My Ambulance Providers</h4> 
                                             <p className='marginme'>"No Record Found"</p> </div>
                                            {/* <div className='col-lg-3'>
                                                <div class="db_box">
                                                    <div className='db_body'>
                                                        <div class="sv_center">
                                                            <div className='sv_photo'><img src="images/user.jpg" /></div>
                                                            <div className='info_text'>
                                                                <h5>Dr. Ashish Sabharwal</h5>
                                                                <p>Orthopedics, Bhopal</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='db_footer'>
                                                        <div className='linkbtn'><a href='#'>Profile </a></div>
                                                        <div className='linkbtn'><a href='#'>Bookings </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div class="db_box">
                                                    <div className='db_body'>
                                                        <div class="sv_center">
                                                            <div className='sv_photo'><img src="images/user.jpg" /></div>
                                                            <div className='info_text'>
                                                                <h5>Dr. Ashish Sabharwal</h5>
                                                                <p>Orthopedics, Bhopal</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='db_footer'>
                                                        <div className='linkbtn'><a href='#'>Profile </a></div>
                                                        <div className='linkbtn'><a href='#'>Bookings </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div class="db_box">
                                                    <div className='db_body'>
                                                        <div class="sv_center">
                                                            <div className='sv_photo'><img src="images/user.jpg" /></div>
                                                            <div className='info_text'>
                                                                <h5>Dr. Ashish Sabharwal</h5>
                                                                <p>Orthopedics, Bhopal</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='db_footer'>
                                                        <div className='linkbtn'><a href='#'>Profile </a></div>
                                                        <div className='linkbtn'><a href='#'>Bookings </a></div>
                                                    </div>
                                                </div>
                                            </div> */}
                                           



                                        </div>


                                    </div>:
                             <div class="defaultloader">
                             <ThreeDots
                                 height="80"
                                 width="80"
                                 radius="9"
                                 color="#056B66"
                                 ariaLabel="three-dots-loading"
                                 wrapperStyle={{}}
                                 wrapperClassName=""
                                 visible={true}
                             /></div> }
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>




        </>
    );
}
export default ServiceProvider;