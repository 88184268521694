import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { HomepageHeader, SidebarUserPanel } from '.';
import moment from 'moment'
import { ProfileAndSlotService } from '../services';
import { ShowProfileType } from '../enums/Enums';
import CheckCircle from "../images/images/check-circle.png"
import userIcon from "../images/adminpanel/UserIcon.svg"
import { useNavigate, useParams } from 'react-router';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import APICore from '../services/APICore';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner'

const baseurl = process.env.REACT_APP_API_BASE_URL
const token = localStorage.getItem("token")

const AccountBookingComponent = ({ loadcarddata, selfDetails, data, isloading, setStatus, status, type, setType, service, carddata, sortby, setsortby, setCardData, filterset }) => {

    const apiFunction = new ProfileAndSlotService();
    const navigateTo = useNavigate()
    const [showAddPrescription, setShowAddPrescription] = useState(false)
    const pagination = useRef();
    const [offset, setOffset] = useState(0);
    const perPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(1);
    const [disableTrue, setDisableTrue] = useState(false)
    const [filter, setfilter] = useState("All")
    const [bookingList2, setbookingList2] = useState([])

    const [bookingList, setBookingList] = useState([])

    useEffect(() => {
        if (carddata !== null) {
            setBookingList(carddata)
            setbookingList2(carddata)
            status="Upcoming"
        }

    }, [carddata])



    // const [serviceProviderId, setServiceProviderId] = useState(0);



    const [searchVal, setSearchVal] = useState("");
    const [paginateList, setPaginateList] = useState([])
    const [userData, setUserdata] = useState({
        username: "",
        bio: "",
        profile_pic: "",
        qualification: "",
        specialization: "",
        experience: "",
        consultation_fees: "",
        video_consult: "",
        slots: [],
        patientStories: []
    })
    const [statuschng, setstatuschng] = useState("Cancelled")
    useEffect(() => {
        var temp = bookingList
        if (searchVal.length > 2) {
            filterthedata(searchVal, temp)
        }
        else {
            setBookingList(carddata)
        }

    }, [searchVal, filter])
    const filterthedata = (value, list) => {
        console.log(value, "undefineddata")
        if (type == "user" && list.length > 0) {

            list = list.filter(item =>
                item.provider_name.toLowerCase().includes(value.toLowerCase()) ||
                item.service.toLowerCase().includes(value.toLowerCase())
            )
        }
        else {
            list = list.filter(item =>
                item.user_name.toLowerCase().includes(value.toLowerCase()) ||
                item.service_type.toLowerCase().includes(value.toLowerCase())
            )
        }
        setBookingList(list)
    }
    useEffect(() => {
        if (filter !== null && filter !== "")
            FilterList(filter)
    }, [filter])


    const FilterList = (filtervalue) => {

        var templist = bookingList;
        var templist2 = bookingList2;

        if (filtervalue === "Ongoing") {
            setBookingList(templist)
        }
        else if (filtervalue === "Confirmed") {

            templist = templist2.filter(x => x.is_confirmed === 1)

            setBookingList(templist)


        }
        else if (filtervalue === "Pending") {

            templist = templist2.filter(x => x.is_confirmed === 0)
            setBookingList(templist)

        }
        else {
            setBookingList(templist)
        }
    }


    // const filterDataBySearch = () => {
    //     let dataArr = carddata.filter(item =>
    //         item.provider_name.toLowerCase().includes(searchVal.toLowerCase()) ||
    //         item.service_type.toLowerCase().includes(searchVal.toLowerCase())
    //     )
    //     setTotalData(dataArr.length)
    //     if (dataArr.length > 0) {
    //         const slice = dataArr.slice(offset, offset + perPage);
    //         setPaginateList(slice);
    //         setPageCount(Math.ceil(dataArr.length / perPage));
    //     } else {
    //         setPaginateList([]);
    //     }
    // }

    // Debounce function
    // const debounce = (fn, delay) => {
    //     let timer;
    //     return (() => {
    //         clearTimeout(timer);
    //         timer = setTimeout(() => fn(), delay);
    //     })();//ifee-->  Immediately Invoked Function Expression
    // };


    const bookingcancel = async (id) => {
        try {
            var data = {
                "status": statuschng
            };

            var config = {

                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const result = await axios.put(`${baseurl}bookings/changebookingstatus/${id}`, JSON.stringify(data), config)
            if (result != null && result !== undefined) {
                toast.success("Booking Cancelled Sucessfully")
                loadcarddata()


            }

        } catch (error) {
            console.log(error)
        }

    }



    // useEffect(() => {
    //     if (searchVal) {
    //         debounce(filterDataBySearch, 1000);
    //     }
    //     else {
    //         loadData();
    //     }

    // }, [searchVal])


    // const loadData = () => {
    //     let dataArr = data
    //     setTotalData(dataArr.length)
    //     if (dataArr.length > 0) {
    //         const slice = dataArr.slice(offset, offset + perPage);
    //         setPaginateList(slice);
    //         setPageCount(Math.ceil(dataArr.length / perPage));
    //     } else {
    //         setPaginateList([]);
    //     }
    // }

    // useEffect(() => {
    //     loadData()
    // }, [number, offset, data])

    // const setPage = ({ selected }) => {
    //     setNumber(selected)
    //     setOffset(perPage * selected)
    // }


    const loadServiceProviderProfile = (userid, serviceType) => {
        if (serviceType === ShowProfileType.type1) {
            apiFunction.LoadDoctorProfileAndSlots(userid).then(result => {
                if (result !== undefined) {

                    if (result.success === true) {
                        setUserdata({
                            username: result.data.doctor.name,
                            bio: result.data.doctor.bio,
                            profile_pic: result.data.doctor.profile_pic,
                            qualification: result.data.doctor.qualification,
                            specialization: result.data.doctor.specialization,
                            experience: result.data.doctor.experience,
                            consultation_fees: result.data.doctor.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }

            })
        } else if (serviceType === ShowProfileType.type2) {
            apiFunction.LoadNursesProfileAndSlots(userid).then(result => {
                if (result !== undefined) {
                    if (result.success === true) {
                        setUserdata({
                            username: result.data.nurse.name,
                            bio: result.data.nurse.bio,
                            profile_pic: result.data.nurse.profile_pic,
                            qualification: result.data.nurse.qualification,
                            specialization: result.data.nurse.specialization,
                            experience: result.data.nurse.experience,
                            consultation_fees: result.data.nurse.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }

            })

        }
        else if (serviceType === ShowProfileType.type3) {
            apiFunction.LoadAmbulancesProfileAndSlots(userid).then(result => {
                if (result !== undefined) {
                    if (result.success === true) {
                        setUserdata({
                            username: result.data.ambulance.name,
                            bio: result.data.ambulance.bio,
                            profile_pic: result.data.ambulance.profile_pic,
                            qualification: result.data.ambulance.qualification,
                            specialization: result.data.ambulance.specialization,
                            experience: result.data.ambulance.experience,
                            consultation_fees: result.data.ambulance.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }
            })
        }

    }
    const joinCallNow = (roomId) => {
        //navigateTo('/video-call', { state: { roomId } })
    }
    let todayDate = new Date()
    console.log(todayDate.getUTCDate() + "-" + (todayDate.getMonth() + 1) + "-" + todayDate.getUTCFullYear())
    let a = new Date((todayDate.getMonth() + 1) + "-" + (todayDate.getUTCDate() + 1) + "-" + todayDate.getUTCFullYear())
    console.log((moment(a).utc()).format(`DD/MM/YYYY`))
    let today = (moment(a).utc()).format(`DD/MM/YYYY`)
   
    return (
        <div className="outerhomepage">

            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <SidebarUserPanel />
                        <div className="right_panel ">
                            <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                <section class="content content-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="content-header">
                                                <h1 class="pull-left">
                                                    My Bookings
                                                </h1>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div >
                                <div class="tab pull-right">
                                    {
                                        service &&
                                        service.map((item) => (
                                            <button onClick={(e) => setType(item.service)}
                                                className={type === item.service ? 'active'
                                                    : ""}>
                                                {item.service}
                                            </button>
                                        ))

                                    }
                                </div>
                            </div> */}
                                    <div class="spacetop">
                                        <div className='row'>
                                            <div className='col-md-6' >
                                                <div class="tab">
                                                    <button onClick={() => setStatus("Upcoming")
                                                    }
                                                        className={status === "Upcoming" ? 'active'
                                                            : ""}>
                                                        Upcoming
                                                    </button>
                                                    <button onClick={() => setStatus("Completed")}
                                                        className={status === "Completed" ? 'active' : ""}>Completed</button>
                                                    <button onClick={() => setStatus("Cancelled")}
                                                        className={status === "Cancelled" ? 'active' : ""}>Cancelled</button>
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <input type="text" value={searchVal} onChange={(e) => setSearchVal(e.target.value)} className='form-control' placeholder='Search...' />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <select className='form-control' value={filter} onChange={(e) => setfilter(e.target.value)}>
                                                            <option value={""}>All</option>
                                                            <option value={"Ongoing"}>Ongoing</option>
                                                            <option value={"Pending"}>Pending</option>
                                                            <option value={"Confirmed"}>Confirmed</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <select className='form-control' value={sortby} onChange={(e) => setsortby(e.target.value)}>
                                                            <option value={""}>Sort by Date</option>
                                                            <option value={"service"}>Sort by Service</option>
                                                            <option value={"service_provider"}>Sort by Service Provider</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {(!isloading) ?
                                            <div className='profile_body'>
                                                <div className='row'>
                                                    {console.log(bookingList, "booklist")}


                                                    {(bookingList !== null && bookingList.length > 0) ?

                                                        (bookingList !== null && bookingList !== undefined && bookingList.length > 0) &&
                                                        bookingList.map((key) => (

                                                            <div className='col-lg-4'>
                                                                <div class="db_box">
                                                                    <div className='db_header'>
                                                                        <h5>{(key.service === "Doctor") ? "Teleconsultation" : (key.service === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                        <div className='dbh_right'>
                                                                            <p>#{key.id}</p>
                                                                            <div className='dropdown' >
                                                                                <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                                <ul class="dropdown-menu ">
                                                                                    <li><Link to={`/bookings-summary/${key.id}`} state={{ type: type }}>Booking Info</Link></li>
                                                                                    {
                                                                                        (type === "user" && key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed") &&
                                                                                        <>
                                                                                            <li><Link onClick={() => bookingcancel(key.id)}>Cancel</Link></li>
                                                                                            <li key={key.service_provider_id + "doctorsNursesAmbulances-list"}>
                                                                                                <Link to="/show-profile-and-slots"
                                                                                                    state={{
                                                                                                        userid: key.provider_id,
                                                                                                        searchType: key.service_type,
                                                                                                        bookingid: key.id,
                                                                                                        scheduleType: "Reschedule"
                                                                                                    }}
                                                                                                >
                                                                                                    Rescheduled
                                                                                                </Link>
                                                                                            </li>
                                                                                            
                                                                                        </>
                                                                                    }
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className='db_body'>
                                                                        <div class="infono">
                                                                            <div className='infpo_icon'> 
                                                                            {type === "user" ?<img src={(key.profile_pic !== null && key.profile_pic !== "null") ?  key.profile_pic : userIcon} alt="user img" />
                                                                             : <img src={(key.patient_profile_pic !== null && key.patient_profile_pic !== "null") ?  key.patient_profile_pic : userIcon} alt="user img" />}
                                                                             </div>
                                                                            <div className='info_text'>
                                                                                <h5>{type === "user" ? key.provider_name : key.booked_by}</h5>
                                                                                {type === "user" ?
                                                                                    <p>{(key.headline)},{key.city}</p>
                                                                                    : <p>{key.booked_for === "self" ?
                                                                                        <p>{(key.gender)},{key.city}</p>
                                                                                        : <p>{key.other_gender},{key.city}</p>
                                                                                    }</p>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='db_footer'>
                                                                        <div className='date'>
                                                                            {(moment(key.appointment_date).utc()).format("MMM DD")}, {(moment(key.appointment_date).utc()).format("hh:mm a")}
                                                                        </div>

                                                                        <div >
                                                                            {(key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed") ?
                                                                                ((key.is_confirmed === 1 && (moment(key.appointment_date).utc()).format("DD/MM/YYYY") === today) ?
                                                                                    <Link to="/video-call" state={{ roomId: key.room_id }}> <i className='fa fa-video'></i>  Join Call</Link> :
                                                                                    key.is_confirmed === 0 ? <a style={{color:"orange"}}>Pending</a> : <a style={{color:"green"}}>Confirmed </a>)
                                                                                : <a style={key.status.toLowerCase() !== "cancelled"?{color:"green"}:{color:"red"}}>{key.status}</a>}
                                                                        </div>


                                                                    </div>
                                                                </div>





                                                                {/* 
                                                            <Card className='mycard' style={{ display: 'none' }}>
                                                                <div className='cardHeader'>
                                                                    <div className='content1'>
                                                                        <Card.Header><h4>{(key.service === "Doctor") ? "Teleconsultation" : (key.service === "Nurse") ? "Home care" : "Ambulance"}</h4></Card.Header>
                                                                    </div>
                                                                    <div className='content2'>
                                                                        <p className='pull right'>#{key.id}</p>
                                                                       

                                                                        <div class="dropdown">
                                                                            <span className='dropdown-toggle' data-toggle="dropdown"><a > <img src={(key.profile_pic != null) ? `${key.profile_pic}` : userIcon} className="user-image" alt="User Image" /></a></span>
                                                                            
                                                                            <ul class="dropdown-menu dropdown-right">
                                                                                <li><Link to={`/bookings-summary/${key.id}`} state={{ type: type }}>Booking Info</Link></li>
                                                                                <li><a onClick={() => bookingcancel(key.id)}>Cancel</a></li>
                                                                                <li><a href="#">Rescheduled</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Card.Body>

                                                                    <Card.Text className='middlebodycards' >
                                                                        <div >
                                                                            <i class="fa fa-user-circle iconclass" aria-hidden="true" ></i>
                                                                        </div>
                                                                        <div>
                                                                            <p>{key.doctor_name}</p>
                                                                            <p>{key.headline}</p>
                                                                        </div>

                                                                    </Card.Text>
                                                                    <div className='buttongroupcard'>
                                                                        <div className='bottombutton'>
                                                                            <Button variant="primary" className='button1'>{(moment(key.appointment_date).utc()).format("DD/MM/YYYY")},
                                                                                {(moment(key.appointment_date).utc()).format("hh:mm a")}</Button>


                                                                        </div>
                                                                        <div className='button2'>
                                                                            
                                                                            {(key.status.toLowerCase()!=="cancelled") ?
                                                                            ((key.is_confirmed === 1 && (moment(key.appointment_date).utc()).format("DD/MM/YYYY") === today) ? 
                                                                            <Link to="/video-call" state={{ roomId: key.room_id }}> Join Call</Link> : 
                                                                            key.is_confirmed === 0 ? "Pending" : "Confirmed ")
                                                                            :"Cancelled"}


                                                                        </div>
                                                                    </div>

                                                                </Card.Body>
                                                            </Card> */}


                                                            </div>

                                                        )) : <p className='marginme'>"No Record Found"</p>}


                                                </div>
                                            </div>
                                            : <div class="defaultloader">
                                                <ThreeDots
                                                    height="80"
                                                    width="80"
                                                    radius="9"
                                                    color="#056B66"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                /></div>}
                                    </div>




                                    <>
                                        {/* {totalData > 10 &&
                                    <div className='mypagination'>
                                        <ReactPaginate
                                            ref={pagination}
                                            pageCount={pageCount}
                                            pageRangeDisplayed={4}
                                            marginPagesDisplayed={0}
                                            onPageChange={setPage}
                                            activeClassName="active"
                                            containerClassName="pagination_ul"
                                            pageClassName="page-item"
                                            breakClassName="page-item"
                                            nextClassName="page-item"
                                            previousClassName="page-item"
                                            breakLabel="..."
                                            previousLabel={<>Prev</>}
                                            nextLabel={<>Next</>}
                                        />
                                    </div>
                                } */}
                                    </>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}
            <div className="modal fade" id="viewServiceProviderDetailsPopup" role="dialog">
                <div className="modal-dialog" style={{ width: "100rem" }}>
                    {/* Modal content*/}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">×</button>
                            <h4 className="modal-title">Service Provider Details</h4>
                        </div>
                        <div className="modal-body">
                            <div className="col-lg-12">
                                {/*Doctor Info*/}
                                <div className="doctor_details bg_light">
                                    <div className="doctor_img">
                                        <div className="dr_photo"><img src={userIcon} alt="" /></div>
                                    </div>
                                    <div className="doctor_bio">
                                        {/* <button className="btn btn_fill" style={{ float: "right" }}>Rate & Review</button> */}
                                        <h3 className="dr_name">{userData.username}</h3>
                                        {userData.specialization &&
                                            <p className="dr_quali">{userData.specialization}</p>
                                        }
                                        {userData.qualification &&
                                            <p className="dr_quali">{userData.qualification}</p>
                                        }
                                        {userData.experience &&
                                            <p className="dr_exp"><img src={CheckCircle} />&nbsp;&nbsp; {userData.experience} Years</p>
                                        }
                                        {/* <p className="dr_votes"><span><img src={ThumbPng} />&nbsp;&nbsp; 96% </span>(935 votes)</p> */}
                                        <div className="dr_desc">
                                            {userData.bio &&
                                                <p className="dr_quali">{userData.bio}</p>
                                            }
                                            {/* Dr. Manohar Saini is a senior consultant Orthopedics specialized in Joint Replacement and Arthroscopic Surgery. He attended KGMC, Lucknow and was trained in Ortho
                                            <span id="dots">...</span>
                                            <span id="more">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
                                            </span>
                                            <a onclick="ReadMore()" id="myBtn" style={{ color: '#056B66', cursor: 'pointer' }}>Read more</a> */}

                                        </div>
                                    </div>
                                </div>
                                {/*Patient Stories*/}
                                {userData.patientStories.length !== 0 &&
                                    <div className="patient_stories bg_light">
                                        <div className="pt_stories_header">
                                            <h3>Patient Stories</h3>
                                        </div>

                                        {userData.patientStories.length === 0 ?
                                            <h4 className='text-center'>No stories found</h4>
                                            :
                                            <>
                                                <div className="pt_stories_body">
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw1"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw2"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="happy_block">
                                                                    <p>Happy with:&nbsp; <span className="happy_tag">Treatment satisfaction</span> <span className="happy_tag">Value for money</span></p>
                                                                </div>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw1"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt_stories_footer">
                                                    <div className="text-center"><a href="#" className="btn btn_fill">View more</a></div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                }

                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-theme radius">Acknowledged</button> w */}
                            <button type="button" className="btn btn-default radius" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}
            <div className="modal fade" id="viewPatientDetailsPopup" role="dialog">
                <div className="modal-dialog" style={{ width: "100rem" }}>
                    {/* Modal content*/}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">×</button>
                            <h4 className="modal-title">Patient Details</h4>
                        </div>
                        <div className="modal-body">
                            <div className="patient_panel">
                                <div className="right_tab">
                                    <div className="patient_header">
                                        <div className="photo">
                                            <img src="images/ptimages.png" />
                                        </div>
                                        <div className="patient_detials">
                                            <h4 className="patient_name">Rabia Khan</h4>
                                            <div className="ptinfo">
                                                <h5><span>Gender:</span> F</h5>
                                                <h5><span>Blood group: </span>“O” +ve</h5>
                                            </div>
                                            <div className="ptinfo">
                                                <h5>Age:<span>27</span></h5>
                                                <h5>Mobile:<span>+91 4586749761</span></h5>
                                                <h5>Email:<span>rabia@khan.com</span></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="patient_body">
                                        <div className="appoint_schedule">
                                            <div className="dateblock">
                                                <div className="date"><span>26</span>&nbsp;Jan</div>
                                                <div className="datedtl">
                                                    <h5>Appointment scheduled at  10:30 AM</h5>
                                                    <p>Records for Vivek Singhal</p>
                                                </div>
                                            </div>
                                            {/* <div className="shareicon">
                                                <p><i className="fa fa-share-alt" /></p>
                                                <span>Follow-up</span>
                                            </div>
                                            <div className="shareicon">
                                                <p><i className="fa fa-share-alt" /></p>
                                                <span>Share</span>
                                            </div> */}
                                            <div className="radiusbtn">
                                                <div className="btn-group pull-right">
                                                    <button type="button" onClick={() => setShowAddPrescription(true)} className="btn btn-info">Add Record &nbsp;<i className="fa fa-plus" /></button>
                                                    <button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown">
                                                        <span className="caret" />
                                                        <span className="sr-only">Toggle Dropdown</span>
                                                    </button>
                                                    <ul className="dropdown-menu" role="menu">
                                                        <li><a href="patient.html">Clinical note</a></li>
                                                        <li><a href="prescription.html">Prescription</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {showAddPrescription ?
                                            <form className="note_form">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Clinical note" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Complaints" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Prescroption</label>
                                                    <textarea className="form-control" rows={5} placeholder spellCheck="false" defaultValue={""} />
                                                </div>
                                                <div className="btnblock">
                                                    <button type="button" onClick={() => setShowAddPrescription(false)} className="btn btn-themeoutline radius btnbig">Cancel</button>
                                                    <button type='button' className="btn btn-theme radius btnbig">Save</button>
                                                </div>
                                            </form>
                                            : ""
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-theme radius">Acknowledged</button>
                            <button type="button" className="btn btn-default radius" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AccountBookingComponent;