import React, { useEffect, useState } from 'react'
import slide01 from "../../images/login/login_slide01.svg"
import slide02 from "../../images/login/login_slide02.svg"
import slide03 from "../../images/login/login_slide03.svg"
import { toast } from 'react-toastify';
import { regEmailValidation, regHtml, regMobile } from '../../helpers/Validate';
import { useNavigate } from 'react-router';
import { OnboardUser } from '../../services';
import { BloodGroupOptions } from '../../components';
import { HtmlErrorMessage } from '../../enums/Enums';


const FillYourPersonalDetails = () => {
    const navigate = useNavigate()
    const apifunction = new OnboardUser();

    // Declaring Object
    const [personDetailsFormInput, SetPersonDetailsFormInput] = useState({
        name: "",
        mobile: "",
        email: '',
        bloodGroup: ''
    })

    const userLoginBy = sessionStorage.getItem("userLoginBy");

    // destructuring
    const { name, mobile, email, bloodGroup } = personDetailsFormInput;


    const onInputChange = e => {
        SetPersonDetailsFormInput({
            ...personDetailsFormInput, [e.target.name]:
                e.target.name === "mobile" ?
                    // Check if not number it will return nothing only for mobile
                    e.target.value.match(regMobile) ? e.target.value : ""
                    : e.target.value
        })
    }


    //if field email or mobile field going empty it will update
    // We are setting here email or mobile 
    useEffect(() => {
        if (userLoginBy === "email") {
            SetPersonDetailsFormInput({
                ...personDetailsFormInput,
                email: localStorage.getItem("mobileOrEmail")
            })
        }
        if (userLoginBy === "mobile") {
            SetPersonDetailsFormInput({
                ...personDetailsFormInput,
                mobile: localStorage.getItem("mobileOrEmail")
            })
        }


    }, [])


    const updatePersonDetails = (e) => {
        e.preventDefault();

        if (!name?.trim()) {
            return toast.error("Enter your name")
        }
        else if (regHtml.test(name?.trim())) {
            return toast.error(HtmlErrorMessage)
        }
        else if (userLoginBy === "email" && mobile.length !== 10) {
            return toast.error("Enter valid mobile number")
        }

        else if (userLoginBy === "mobile" && !email?.trim()) {
            return toast.error("Please enter email")
        }
        else if (userLoginBy === "mobile" && !regEmailValidation.test(email?.trim())) {
            return toast.error("Enter valid email")
        }
        else {
            const data = {
                name: name?.trim(),
                mobile: mobile,
                email: email?.trim(),
                blood_group: bloodGroup,
                userid: localStorage.getItem("userid")//User ID-getting from api firstly saved in storage
            }
            apifunction.UpdateYourPersonDetails(data).then(res => {
                if (res !== undefined) {
                    var result = res
                    if (result.success === true) {
                        const userid = result.data.id
                        const mobileNumber = result.data.mobile
                        const stepCompleted = result.data.completed_step
                        const username = result.data.name
                        const useremail = result.data.email
                        const userType = result.data.user_type
                        if (stepCompleted <= 3) {
                            localStorage.setItem("userid", userid)
                            localStorage.setItem("user_mobile", mobileNumber)
                            localStorage.setItem("user_name", username)
                            localStorage.setItem("user_email", useremail)
                            localStorage.setItem("user_type", userType)
                            const notLoggedInBookingFlowUrlDetails = sessionStorage.getItem("notLoggedInBookingFlowUrlDetails")
                            const slotDetails = JSON.parse(notLoggedInBookingFlowUrlDetails)
                            if (slotDetails) {
                                navigate('/booking-fill-form', {
                                    state: {
                                        slotid: slotDetails.slotid,
                                        slotDate: slotDetails.slotDate,
                                        slotTime: slotDetails.slotTime,
                                        doctorName: slotDetails.doctorName,
                                        doctorFee: slotDetails.doctorFee,
                                        profileId: slotDetails.profileId,
                                        profileType: slotDetails.profileType
                                    }
                                })
                            } else {
                                navigate("/my-dashboard")
                            }
                            return toast.success(result.message)
                        }
                    }
                }
            })
        }
    }
    return (
        <div className="login_page">
            <div className="login_left">
                <div className="left_img">
                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                        {/* Indicators */}
                        <ol className="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to={0} className="active" />
                            <li data-target="#myCarousel" data-slide-to={1} />
                            <li data-target="#myCarousel" data-slide-to={2} />
                        </ol>
                        {/* Wrapper for slides */}
                        <div className="carousel-inner">
                            <div className="item active">
                                <img src={slide01} alt style={{ width: '100%' }} />
                            </div>
                            <div className="item">
                                <img src={slide02} alt style={{ width: '100%' }} />
                            </div>
                            <div className="item">
                                <img src={slide03} alt style={{ width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="login_right">
                <div className="login_right_content">
                    <form onSubmit={updatePersonDetails} role="form" className="signup_form">
                        <h2 className="title">Fill-up your personal details</h2>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Name</label>
                            <input type="text" name='name' value={name} onChange={(e) => onInputChange(e)} className="form-control" />
                        </div>
                        {userLoginBy === "email" ?
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Phone</label>
                                <input type="text" value={mobile} maxLength="10" name='mobile' onChange={(e) => onInputChange(e)} className="form-control" />
                            </div>
                            :
                            userLoginBy === "mobile"
                                ?
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Email</label>
                                    <input type="text" value={email} onChange={(e) => onInputChange(e)} name='email' className="form-control" />
                                </div>
                                : ""
                        }
                        <div className="mt40">
                            <div className><button type='submit' className="btn btn_fill  btn-block">Done</button></div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default FillYourPersonalDetails;