import React, { useState } from 'react'
import { HomepageHeader, SidebarUserPanel } from '../../../components'
import { useLocation } from 'react-router'
import moment from 'moment'


const MyPatientProfileView = () => {

    const [status, setStatus] = useState("Profile")
    const { state } = useLocation();

    let profileData = state.profileData.profile
    let bookings = state.profileData.bookings
    console.log(profileData,"profiledat ---- ")
  

    return (
        <div className="outerhomepage">
           
           
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <SidebarUserPanel />
                        <div className="right_panel table-responsive" style={{ marginTop: "20px" }}>
                            <div className='col-md-12' style={{ marginTop: "22px" }}>
                                <div class="tab col-md-12">
                                    <button onClick={() => setStatus("Profile")}
                                        className={status === "Profile" ? 'active'
                                            : ""}> Profile
                                    </button>
                                    {<button onClick={() => setStatus("Bookings")}
                                        className={status === "Bookings" ? 'active' : ""}>Total Bookings</button>}
                                </div>
                            </div>
                            <div style={{ padding: "20px" }}>
                                {
                                    status === 'Profile' &&                                  
                                    <table className='table-details-align'>
                                        <tr>
                                            <th>Name</th>
                                            <td>{profileData[0].name}</td>
                                        </tr>
                                        <tr>
                                            <th>Mobile</th>
                                            <td>{profileData[0].mobile}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{profileData[0].email}</td>
                                        </tr>
                                        <tr>
                                            <th>Address</th>
                                            <td>{profileData[0].complete_address}</td>
                                        </tr>
                                        <tr>
                                            <th>City</th>
                                            <td>{profileData[0].city}</td>
                                        </tr>
                                        <tr>
                                            <th>State</th>
                                            <td>{profileData[0].state}</td>
                                        </tr>
                                        <tr>
                                            <th>Country</th>
                                            <td>{profileData[0].country}</td>
                                        </tr>
                                        <tr>
                                            <th>Zip Code</th>
                                            <td>{profileData[0].zipcode}</td>
                                        </tr>
                                    </table>
                                }
                            </div>
                            <div>
         
                                {
                                    
                                    status === 'Bookings' &&
                                    <table className="table mytable">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: '12.5%' }}>Booking Id</th>
                                                <th style={{ width: '12.5%' }}>Date/Time</th>
                                                <th style={{ width: '12.5%' }}>Name</th>
                                                <th style={{ width: '12.5%' }}>Appointment Date</th>
                                            </tr>
                                            {bookings.length === 0 ? <tr><td colSpan={8}>No records found</td></tr> :
                                                bookings.map((item, key) =>
                                                (
                                                    <tr>
                                                        <td>
                                                            <div className="userdtl">
                                                                <div className="userinfo">
                                                                    {item.id}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="bookedat">
                                                                <p>{(moment(item.booking_date).utc()).format("DD/MM/YYYY")} </p>
                                                                <span>
                                                                    {(moment(item.booking_date).utc()).format("hh:mm a")}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.name}
                                                        </td>
                                                        <td>
                                                            <div className="bookedat">
                                                                <p>{(moment(item.appointment_date).utc()).format("DD/MM/YYYY")} </p>
                                                                <span>
                                                                    {(moment(item.appointment_date).utc()).format("hh:mm a")}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}


        </div>
    )
}

export default MyPatientProfileView;