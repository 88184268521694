import axios from 'axios';
import * as process from "process";
import { toast } from 'react-toastify';



// Routes define here 
// descrption:- last updated 22-06-22
// descrption:- By Mayur
class SearchServices {
    constructor() {
        this.state = {
            ApibaseURL: process.env.REACT_APP_API_BASE_URL,
            AccessToken: localStorage.getItem('token'),
        }
    }

    async searchResultFunc(api) {
     
        try {
            console.log(api,"pagetype---")
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
          
            const response = await axios.get(`${this.state.ApibaseURL + "/" + api}`, headers).then((response) => {
                return response
                console.log(response,"search response")
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

}
export default SearchServices;
