import APIHandler from '../services/APICore';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useLocation } from 'react-router';
const Bookingsdetails = ({ apidata }) => {
    let {state}=useLocation()
    const [speci, setspeci] = useState(apidata)
    const [mdcal,setMdcal]=useState("")
   
    const { id,
        booking_type,
        notes, room_id,
        allergy,
        appointment_date,
        booked_for,
        booking_date,

        call_end_time,
        call_start_time,
        experience,
        is_active,
        is_confirmed,
        is_video_consultancy,
        medical_history,
        on_medication,
        other_age,
        other_gender,
        other_mobile,
        other_name,
        paid_amount,
        past_medical_issue,
        payment_mode,
        pickup_address,
        profile_pic,
        progress,
        provider_email,
        provider_id,
        provider_mobile,
        provider_name,
        qualification,
        reason,
        remark,
        service,
        service_provider_user_id,
        service_type,
        short_description,
        slots,
        specializations,
        status,
        total_amount,
        tran_id } = apidata
    console.log(apidata, allergy,on_medication,past_medical_issue)
    console.log(speci, "dshdsh")

    useEffect(()=>{
        var temp=mdcal;
        if(apidata.allergy===1 || apidata.allergy===true){
            if(temp==""){
                temp+="allergy, "
            }
            else{
                temp+="allergy"
            }
        }
        if(apidata.on_medication===1 || apidata.on_medication===true){
            if(temp==""){
                temp+="on_medication, "
            }
            else{
                temp+="on medication, "
            }
            
        }
        if(apidata.past_medical_issue===1 || apidata.past_medical_issue===true){
            if(temp==""){
                temp+="past medical issue, "
            }
            else{
                temp+="past medical issue"
            }
        }

        setMdcal(temp)
    },[allergy])




    return (
        <>
            <div className='profile_body'>
                <div className="row">
                    <div className="col-lg-3">
                        <div class="fd_input  mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Service</label>
                                <input type="text" class="form-control" value={apidata.service == "Doctor" ? "Teleconsultation" : apidata.service == "Nurse" ? "Homecare" :apidata.service == "Ambulance" ?"Ambulance":""} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div class="fd_input  mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Status</label>
                                <input type="text" value={(apidata.status)} class="form-control" placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div class=" fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Booking Date & Time</label>
                                <input type="text" class="form-control" value={(moment(apidata.booking_date).utc()).format("DD/MM/YYYY") + "," + (moment(apidata.appointment_date).utc()).format("hh:mm a")} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class="fd_input  mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Appointment/Schedule Date</label>
                                <input type="text" class="form-control" value={(moment(apidata.appointment_date).utc()).format("DD/MM/YYYY") + "," + (moment(apidata.appointment_date).utc()).format("hh:mm a")} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div class="fd_input  mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Fee Paid</label>
                                <input type="text" class="form-control" value={(apidata.paid_amount)} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-lg-12"><h4 class="page_title">Service Provider</h4>  </div>
                    <div className="col-lg-6">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Name</label>
                                <input type="text" class="form-control" value={apidata.provider_name} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Address</label>
                                <input type="text" class="form-control" value={apidata.service_provider_address} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Specialization</label>
                                <input type="text" class="form-control" value={(apidata != undefined && apidata.specializations != null) && (apidata.specializations).map((key) => (key.title))} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Email</label>
                                <input type="text" class="form-control" value={apidata.provider_email} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class=" fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                                <input type="text" class="form-control" value={apidata.provider_mobile} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-lg-12"><h4 class="page_title">User Details</h4>  </div>
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Booked By</label>
                                <input type="text" class="form-control" value={apidata.booked_by} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Address</label>
                                <input type="text" class="form-control" value={apidata.user_address} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Email</label>
                                <input type="text" class="form-control" value={apidata.user_email} placeholder="" disabled={true}/>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                                <input type="text" class="form-control" value={apidata.other_mobile} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                </div>   
                <div className="row ">
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Booked For</label>
                                <input type="text" class="form-control" value={apidata.booked_for} placeholder="" disabled={true}/>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Name</label>
                                <input type="text" class="form-control" value={apidata.other_name} placeholder="" disabled={true}/>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Age</label>
                                <input type="text" class="form-control" value={apidata.other_age} placeholder="" disabled={true}/>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="row ">
                    <div className="col-lg-12">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Medical History(If Any)</label>
                                <textarea class="form-control" value={mdcal} id="exampleFormControlTextarea1" rows="3" disabled={true}></textarea>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-12">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Notes(If Any)</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" value={apidata.remark} rows="3" disabled={true}></textarea>
                            </div>
                        </div>


                    </div>













                </div>
            </div>







        </>
    );
}
export default Bookingsdetails;