import { toast } from 'react-toastify';
let ApibaseURL= process.env.REACT_APP_API_BASE_URL;

export const HomeCareRegistered = async (reqdata,token) => {
    try {
      const response = await fetch(`${ApibaseURL}/onboarding/nurse`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: token,
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(reqdata),
      });
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
        
      }
      console.log(response)
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
        toast.error("Something went wrong , Please try again later")
    }
  };