import React, { useEffect, useState } from 'react'
import MyPatientComponent from '../../../components/MyPatientComponent'
import { ServicesProviderService } from '../../../services'

const MyPatient = () => {

    const apiFunction = new ServicesProviderService()
    const [datalist, setDatalist] = useState([])
    const [isloading, setIsLoading] = useState(false)
    const [status, setStatus] = useState("Booked")
    const service = JSON.parse(localStorage.getItem("services"));
    const [type, setType] = useState("");
    
    useEffect(() => {
        if (service && service.length > 0) {
            setType(service[0].service);
        }
    }, [])
    useEffect(() => {
        setIsLoading(true);
        if (type && type !== "")
            loadBookingsAllBooking();
    }, [status, type])
    const loadBookingsAllBooking = () => {
        apiFunction.loadPatientsDetails(type, status).then(result => {
            console.log('doctor',result)
            if (result !== undefined) {
                if (result.success === true) {
                    setDatalist(result.data)
                    setIsLoading(false);
                }
            }
        })
    }

    return (
        <MyPatientComponent setStatus={setStatus} type={type} setType={setType} service={service} status={status} data={datalist} selfDetails={true} isloading={isloading} />
    )
}

export default MyPatient;